import { Pipe, PipeTransform } from '@angular/core';
import { ActualcompanySrorageService } from '../storages/actualcompany-srorage.service';
import { of, Observable } from 'rxjs';
import { KeyValue } from '@angular/common';

@Pipe({
  name: 'actualcompany'
})
export class ActualcompanyPipe implements PipeTransform {

  constructor(private actualcompanySrorageService: ActualcompanySrorageService) {
  }

  transform(): Observable<Array<KeyValue<string, string>>> {
    return this.actualcompanySrorageService.getItems();
  }
}
