<div class="frame">
    <div class="header">
        <div style="flex: 1;">
            <!-- 搜索框 -->
            <nz-input-group [nzSuffix]="suffixIconSearch">
                <input type="text" nz-input placeholder="搜索" (ngModelChange)="searchModelChange($event)" />
            </nz-input-group>
        </div>
        <!-- 新增根节点 -->
        <div style="margin-left: 8px;">
            <button nz-button nzType="primary" title="新增根节点" (click)="addNewNode()">
                <i nz-icon nzType="plus" nzTheme="outline"></i>
            </button>
        </div>
    </div>
    <!-- 树组件 -->
    <div class="tree">
        <nz-tree-view [nzTreeControl]="treeControl" [nzDataSource]="dataSource">
            <nz-tree-node *nzTreeNodeDef="let node" nzTreeNodeIndentLine style="align-items: center;">
                <nz-tree-node-toggle nzTreeNodeNoopToggle>
                    <i nz-icon nzType="file" nzTheme="outline"></i>
                </nz-tree-node-toggle>
                <nz-tree-node-option [nzDisabled]="node.disabled" [nzSelected]="selectListSelection.isSelected(node)"
                    (nzClick)="selectListSelection.toggle(node)">
                    {{ node.label }}
                </nz-tree-node-option>
                <div [hidden]="!selectListSelection.isSelected(node)">
                    <a style="margin-left: 4px;" (click)="addNewNode(node)">
                        <i nz-icon nzType="plus" nzTheme="outline"></i>
                    </a>
                    <a style="margin-left: 4px;" (click)="editNode(node)">
                        <i nz-icon nzType="edit" nzTheme="outline"></i>
                    </a>
                    <a style="margin-left: 4px; margin-right: 4px;" (click)="deleteNode(node)">
                        <i nz-icon nzType="minus" nzTheme="outline"></i>
                    </a>
                </div>
            </nz-tree-node>
            <nz-tree-node style="align-items: center;" *nzTreeNodeDef="let node; when: hasChild" nzTreeNodeIndentLine>
                <nz-tree-node-toggle *ngIf="!node.loading">
                    <i nz-icon [nzType]="treeControl.isExpanded(node) ? 'minus-square' : 'plus-square'"
                        nzTheme="outline" *ngIf="!node.isLeaf"></i>
                </nz-tree-node-toggle>
                <nz-tree-node-toggle *ngIf="node.loading" nzTreeNodeNoopToggle>
                    <i nz-icon nzType="loading" nzTreeNodeToggleActiveIcon *ngIf="!node.isLeaf"></i>
                </nz-tree-node-toggle>
                <nz-tree-node-option [nzDisabled]="node.disabled" [nzSelected]="selectListSelection.isSelected(node)"
                    (nzClick)="selectListSelection.toggle(node)">
                    {{ node.label }}
                </nz-tree-node-option>
                <div [hidden]="!selectListSelection.isSelected(node)">
                    <a style="margin-left: 4px;" (click)="addNewNode(node)">
                        <i nz-icon nzType="plus" nzTheme="outline"></i>
                    </a>
                    <a style="margin-left: 4px;" (click)="editNode(node)">
                        <i nz-icon nzType="edit" nzTheme="outline"></i>
                    </a>
                    <a style="margin-left: 4px; margin-right: 4px;" (click)="deleteNode(node)">
                        <i nz-icon nzType="minus" nzTheme="outline"></i>
                    </a>
                </div>
            </nz-tree-node>
        </nz-tree-view>
    </div>
</div>

<ng-template #suffixIconSearch>
    <i nz-icon nzType="search"></i>
    <!-- <i nz-icon nzType="search"></i> -->
    <!-- <i nz-icon nzType="close"></i> -->
</ng-template>