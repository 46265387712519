import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-title',
  templateUrl: './title.component.html',
  styleUrls: ['./title.component.less']
})
export class TitleComponent implements OnInit, OnChanges {
  @Input() iconName: string;
  @Input() title: string;
  @Input() size = '24px';

  public imageSrc: string = null;

  constructor() { }

  ngOnInit(): void {
    this.initIconName(this.iconName);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.iconName && changes.iconName.currentValue) {
      this.initIconName(this.iconName);
    }
  }

  initIconName(iconName: string): void {
    if (iconName) {
      const url = `../../assets/images/icons/${this.iconName}.svg`;
      this.imageSrc = url;
    }
  }

}
