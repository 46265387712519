import { Pipe, PipeTransform } from '@angular/core';
import { CityStorageService } from '../storages/city-storage.service';
import { Observable, of } from 'rxjs';

@Pipe({
  name: 'cityName'
})
export class CityNamePipe implements PipeTransform {

  constructor(private cityStorageService: CityStorageService) { }

  /**
   * transform
   * @param value 城市编码
   */
  transform(value: any): Observable<string> {
    if (!value) {
      return of('');
    }

    return this.cityStorageService.getName(value);
  }
}
