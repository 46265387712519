import { Component, OnInit, Input, TemplateRef } from '@angular/core';

@Component({
  selector: 'app-bottom-panel',
  templateUrl: './bottom-panel.component.html',
  styleUrls: ['./bottom-panel.component.less']
},
)
export class BottomPanelComponent implements OnInit {
  @Input() template: TemplateRef<any>;

  constructor() { }

  ngOnInit(): void {
  }

}
