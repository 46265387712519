<div class="frame" (click)="stopPropagationClickEvent($event)" (touchend)="stopPropagationClickEvent($event)"
    (touchstart)="stopPropagationClickEvent($event)">
    <!-- 下拉项 -->
    <a>
        <nz-input-group [nzSuffix]="suffixIconDown">
            <input #input type="text" nz-input [disabled]="disabled" placeholder="请选择"
                (click)="dropDownClick($event)" />
        </nz-input-group>
        <ng-template #suffixIconDown>
            <i nz-icon nzType="down"></i>
            <!-- <i nz-icon nzType="search"></i> -->
            <!-- <i nz-icon nzType="close"></i> -->
        </ng-template>
    </a>
    <!-- 下拉组件相对容器 -->
    <div class="dropdown-frame">
        <!-- 下拉组件 -->
        <div #dropDowmPop [hidden]="dropdownHidden" class="dropdownpop">
            <nz-tree-view [nzTreeControl]="treeControl" [nzDataSource]="dataSource" style="flex: 1;">
                <nz-tree-node *nzTreeNodeDef="let node" nzTreeNodeIndentLine style="align-items: center;">
                    <nz-tree-node-toggle nzTreeNodeNoopToggle>
                        <i nz-icon nzType="file" nzTheme="outline"></i>
                    </nz-tree-node-toggle>
                    <nz-tree-node-option *ngIf="allowSelectedNode(node.type); else leafElseTemplate"
                        [nzDisabled]="node.disabled" [nzSelected]="selectListSelection.isSelected(node)"
                        (nzClick)="nodeClickEvent(node)">
                        <span>{{ node.label }}</span>
                    </nz-tree-node-option>
                    <ng-template #leafElseTemplate>
                        <div>{{ node.label }}</div>
                    </ng-template>
                </nz-tree-node>
                <nz-tree-node *nzTreeNodeDef="let node; when: hasChild" nzTreeNodeIndentLine
                    style="align-items: center;">
                    <nz-tree-node-toggle *ngIf="!node.loading">
                        <i nz-icon [nzType]="treeControl.isExpanded(node) ? 'minus-square' : 'plus-square'"
                            nzTheme="outline" *ngIf="!node.isLeaf"></i>
                    </nz-tree-node-toggle>
                    <nz-tree-node-toggle *ngIf="node.loading" nzTreeNodeNoopToggle>
                        <i nz-icon nzType="loading" nzTreeNodeToggleActiveIcon *ngIf="!node.isLeaf"></i>
                    </nz-tree-node-toggle>
                    <nz-tree-node-option *ngIf="allowSelectedNode(node.type); else elseTemplate"
                        [nzDisabled]="node.disabled" [nzSelected]="selectListSelection.isSelected(node)"
                        (nzClick)="nodeClickEvent(node)">
                        <span>{{ node.label }}</span>
                    </nz-tree-node-option>
                    <ng-template #elseTemplate>
                        <div>{{ node.label }}</div>
                    </ng-template>
                </nz-tree-node>
            </nz-tree-view>
        </div>
    </div>
</div>