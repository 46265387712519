import {
  Component,
  OnInit,
  forwardRef,
  OnDestroy,
  ViewChild,
  ElementRef,
  Input,
  SimpleChanges,
  OnChanges
} from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';

import { AmapLoaderService } from 'src/app/shared/loaders/amap-loader.service';
import { MapPointInputModel } from '../../models/map-point-input.model';
import { NzMessageService } from 'ng-zorro-antd/message';

declare var AMap: any;

const EXE_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  // tslint:disable-next-line: no-use-before-declare
  useExisting: forwardRef(() => MapPointComponent),
  multi: true
};

@Component({
  selector: 'app-map-point',
  templateUrl: './map-point.component.html',
  styleUrls: ['./map-point.component.less'],
  providers: [EXE_VALUE_ACCESSOR]
})
export class MapPointComponent implements ControlValueAccessor, OnInit, OnChanges, OnDestroy {

  @ViewChild('map', { static: true }) mapDom: ElementRef<HTMLElement>;

  private amap: any;
  private geocoder: any;
  private locatedMarker: any;
  private position: Array<number> = [108.834332, 34.205804];

  public address = '';
  public model: MapPointInputModel;

  constructor(
    private amapLoaderService: AmapLoaderService,
    private message: NzMessageService,
  ) { }

  ngOnInit(): void {
    this.mapinit();
  }

  public mapinit(): void {
    // 脚本加载完成
    this.amapLoaderService.load().then(amapResponse => {
      this.geocoder = new AMap.Geocoder({
        city: '全国', // 城市设为陕西，默认：“全国”
        radius: 100 // 范围，默认：500
      });

      // 初始化地图
      this.amap = new AMap.Map(this.mapDom.nativeElement, {
        viewMode: '2D',
        features: ['bg', 'point', 'road'], // 'bg'（地图背景）、'point'（POI点）、'road'（道路）、'building'（建筑物)
        jogEnable: false,
        rotateEnable: false,
        animateEnable: false,
        doubleClickZoom: false,
        keyboardEnable: false,
        showLabel: true,
        showBuildingBlock: false,
        zooms: [1, 30],
        zoom: 18,
        center: this.position,
        layers: [
          AMap.createDefaultLayer()
        ]
      });

      // 注册地图移动事件
      this.amap.on('mapmove', (event) => {
        const latLng = this.amap.getCenter();
        if (this.locatedMarker && latLng) {
          // 设置中心点
          this.locatedMarker.setPosition([latLng.lng, latLng.lat]);
        }
      });

      // 注册地图拖拽事件
      this.amap.on('moveend', (event) => {
        const latLng = this.amap.getCenter();
        if (this.locatedMarker && latLng) {
          // 逆地址解析
          this.geocoder.getAddress([latLng.lng, latLng.lat], (status, result) => {
            if (status === 'complete' && result.regeocode) {
              const addressComponent = result.regeocode.addressComponent;
              // 值变更
              this.changeValue({
                lng: latLng.lng,
                lat: latLng.lat,
                province: addressComponent.province,
                city: addressComponent.city,
                district: addressComponent.district,
                adcode: addressComponent.adcode,
                address: result.regeocode.formattedAddress
              });
            }
          });
        }
      });

      // 逆地址解析
      this.geocoder.getAddress(this.position, (status, result) => {
        if (status === 'complete' && result.regeocode) {
          const addressComponent = result.regeocode.addressComponent;
          // 值变更
          this.changeValue({
            lng: this.position[0],
            lat: this.position[1],
            province: addressComponent.province,
            city: addressComponent.city,
            district: addressComponent.district,
            adcode: addressComponent.adcode,
            address: result.regeocode.formattedAddress
          });
        }
      });
      // 添加Marker
      this.locatedMarker = new AMap.Marker({
        map: this.amap,
        position: this.position,
        offset: new AMap.Pixel(-18, -36),
        icon: new AMap.Icon({
          image: 'assets/images/zuobiao.gif',
          size: new AMap.Size(36, 36),  // 图标大小
          imageSize: new AMap.Size(36, 36),
        })
      });
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
  }

  ngOnDestroy(): void {
    if (this.locatedMarker) {
      this.locatedMarker.setMap(null);
    }
    if (this.amap) {
      // 清空地图
      this.amap.clearMap();
    }
  }

  public onModelChange = (_: any) => { };

  public onModelTouched = (_: any) => { };

  public writeValue(value: MapPointInputModel): void {
    if (value && value.lat && value.lng) {
      // 初始化点
      this.position = [value.lng, value.lat];
      this.address = value.address;
      // 位置
      this.model = value;
      if (this.position) {
        this.amap.setCenter(this.position);
      }
    }
  }

  public registerOnChange(fn: any): void {
    this.onModelChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onModelTouched = fn;
  }

  /**
   * 搜索地点事件
   * @param address 地址
   */
  public queryEvent(address: string): void {
    // 地址解析
    if (this.geocoder) {
      this.geocoder.getLocation(address, (status: string, result: any) => {
        if (status === 'complete' && result.geocodes.length) {
          const model = result.geocodes[0].location;
          const lngLat: Array<any> = model.location.split(',');

          // 经纬度
          if (lngLat && lngLat.length) {
            this.locatedMarker.setPosition([lngLat[0], lngLat[1]]);
            // 位置信息解析
            this.amap.setFitView(this.locatedMarker);
            // 值更新
            this.changeValue({
              adcode: model.adcode,
              province: model.province,
              city: model.city,
              district: model.district,
              lat: lngLat[1],
              lng: lngLat[0],
              address: model.formattedAddress
            });
          }
        } else {
          // log.error('根据地址查询位置失败');
        }
      });
    }
  }

  /**
   * 值改变
   * @param model 图片路径
   */
  private changeValue(model: MapPointInputModel): void {
    if (model) {
      this.position = [model.lng, model.lat];
      this.address = model.address;
      this.amap.setCenter(this.position);
      // 更新地图中心点
      if (this.amap) {
        this.amap.setCenter(this.position);
      }
    }
    this.model = model;

    // 更新值
    this.onModelChange(model);
  }

}
