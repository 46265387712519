/**
 * 信息报送查询模型
 */
export class InfoSubSearchModel {
    /**
     * 主键
     */
    id?: string;
    /**
     * 开始时间
     */
    startDate?: Date;
    /**
     * 结束时间
     */
    endDate?: Date;
    /**
     * 单位ID
     */
    unitId?: string;
    /**
     * 标题
     */
    caption?: string;
    /**
     * 内容
     */
    content?: string;
    /**
     * 状态
     */
    status?: number;
    /**
     * 数据状态
     */
    dataStatus?: number;
    /**
     * 创建时间
     */
    created?: Date;
    /**
     * 创建人
     */
    createdBy?: string;
    /**
     * 创建人姓名
     */
    createdByName?: string;
    /**
     * 修改时间
     */
    lastModified?: Date;
    /**
     * 修改人
     */
    lastModifiedBy?: string;
    /**
     * 修改人姓名
     */
    lastModifiedByName?: string;
    /**
     * 状态
     */
    rowVersion?: Date;

    /**
     * 构造器
     * @param init 初始化数据
     */
    constructor(init?: Partial<InfoSubSearchModel>) {
        Object.assign(this, init);
    }
}
