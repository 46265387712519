import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'desensitization'
})
export class DesensitizationPipe implements PipeTransform {

  transform(value: string, number1: number, number2: number): any {
    let result = '';
    if (value) {
      const len = value.length;
      const firstStr = value.substr(0, number1);
      const lastStr = value.substr(number2);
      const middleStr = value.substring(number1, len - Math.abs(number2)).replace(/[\s\S]/ig, '*');
      result = firstStr + middleStr + lastStr;
      return result;
    } else {
      return '';
    }
  }
}
