import { Injectable } from '@angular/core';
import { observable, of, Observable } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { catchError, map, tap } from 'rxjs/operators';

import { BaseService } from '../../shared/base.service';

import { PagedResponse } from '../../shared/models/paged-response';
import { ListResponse } from '../../shared/models/list-response';
import { SingleResponse } from '../../shared/models/single-response';
import { NocontentResponse } from '../../shared/models/nocontent-response';

import { OrgModel } from '../models/org.model';
import { OrgParameter } from '../models/org-parameter';
import { OrgSearchModel } from '../models/org-search.model';
import { LabelValueModel } from '../../shared/models/label-value.model';
import { OrgDetailsModel } from '../models/org-details.model';
import { KeyValueParentModel } from '../../shared/models/key-value-parent.model';

@Injectable({
  providedIn: 'root'
})
export class OrgService extends BaseService {
  // URL to web api
  private apiUrl = `${this.apiUrlBase}/api/organization`;

  constructor(
    private http: HttpClient
  ) {
    super();
  }

  /**
   * 获取组织机构字典
   */
  getAll(): Observable<ListResponse<KeyValueParentModel<string, string, string>>> {
    const url = `${this.apiUrl}/getAll`;
    return this.http.get<ListResponse<KeyValueParentModel<string, string, string>>>(url);
  }

  /**
   * 获取机构分页集合
   * @param parameter 查询参数
   */
  paged(parameter: OrgParameter): Observable<PagedResponse<OrgSearchModel>> {
    // 参数处理
    const params = this.removeEmptyProps(parameter);
    return this.http.get<PagedResponse<OrgSearchModel>>(this.apiUrl, { params });
  }

  /**
   * 获取所有机构树结构
   * @param exceptId 排除部分不参与构造的节点
   */
  getTree(exceptId?: string): Observable<ListResponse<LabelValueModel<string, string>>> {
    const url = `${this.apiUrl}/getTree`;

    const params = this.removeEmptyProps({ exceptId });
    return this.http.get<ListResponse<LabelValueModel<string, string>>>(url, { params });
  }

  /**
   * 获取机构详情
   * @param id 机构ID
   */
  details(id: string): Observable<SingleResponse<OrgDetailsModel>> {
    const url = `${this.apiUrl}/${id}`;
    return this.http.get<SingleResponse<OrgDetailsModel>>(url);
  }

  /**
   * 新增机构
   * @param inputModel 机构实体
   */
  add(inputModel: OrgModel): Observable<SingleResponse<OrgModel>> {
    return this.http.post<SingleResponse<OrgModel>>(this.apiUrl, inputModel, this.httpOptions);
  }

  /**
   * 更新机构
   * @param inputModel 机构实体
   */
  update(inputModel: OrgModel): Observable<NocontentResponse> {
    // 请求url
    const url = `${this.apiUrl}/${inputModel.id}`;
    return this.http.put<NocontentResponse>(url, inputModel, this.httpOptions);
  }

  /**
   * 撤销删除
   * @param id ID
   */
  undelete(id: string): Observable<NocontentResponse> {
    const url = `${this.apiUrl}/undelete/${id}`;
    return this.http.delete<NocontentResponse>(url, this.httpOptions);
  }

  /**
   * 删除机构
   * @param id 机构ID
   */
  delete(id: string): Observable<NocontentResponse> {
    const url = `${this.apiUrl}/${id}`;
    return this.http.delete<NocontentResponse>(url, this.httpOptions);
  }
}
