import { Pipe, PipeTransform } from '@angular/core';
import { environment } from 'src/environments/environment';

@Pipe({
  name: 'imgSrc'
})
export class ImgSrcPipe implements PipeTransform {
  private baseUrl = environment.SERVER.URL;

  transform(value: string): any {
    let result = '';
    if (value) {
      result = `${this.baseUrl}/${value}`;
    }

    return result;
  }
}
