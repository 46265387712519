export enum OwnerType {
  /**
   * 未知
   */
  UnKnown = 0,
  /**
   * 个人
   */
  Personal = 1,
  /**
   * 单位
   */
  Company = 2
}
/**
 * 机构类型
 */
export enum OrgType {
  /**
   * 组织
   */
  Organization = 1,
  /**
   * 小区
   */
  Community = 2,
  /**
   * 楼栋
   */
  Building = 10,
  /**
   * 单元
   */
  Unit = 11,
  /**
   * 房屋
   */
  House = 12,
  /**
   * 设备
   */
  Device = 20
}

/**
 * 是否
 */
export enum YesNo {
  /**
   * 未知
   */
  UnKnown = 0,
  /**
   * 是
   */
  Yes = 1,
  /**
   * 否
   */
  No = 2
}

/**
 * 删除状态
 */
export enum Deleted {
  /**
   * 未知
   */
  UnKnown = 0,
  /**
   * 是
   */
  Normal = 1,
  /**
   * 否
   */
  Deleted = 2
}

/**
 * 审批状态
 */
export enum ApprovalStatus {
  /**
   * 未知
   */
  UnKnown = 0,
  /**
   * 待审批
   */
  PendingApproval = 1,
  /**
   * 同意
   */
  Agree = 2,
  /**
   * 拒绝
   */
  Refused = 3
}

/**
 * 处理状态
 */
export enum DealStatus {
  /**
   * 未知
   */
  UnKnown = 0,
  /**
   * 待审批
   */
  PendingDeal = 1,
  /**
   * 同意
   */
  Agree = 2,
  /**
   * 拒绝
   */
  Refused = 3,
  /**
   * 已确认
   */
  Confirm = 4,
}

/**
 * 证件类型
 */
export enum DocumentType {
  /**
   * 未知
   */
  UnKnown = 0,
  /**
   * 身份证
   */
  IDCard = 1
}

/**
 * 限养区类型
 */
export enum AreaType {
  /**
   * 未知
   */
  UnKnown = 0,
  /**
   * 一般限养区
   */
  General = 1,
  /**
   * 重点限养区
   */
  Special = 2
}

/**
 * 缴费项枚举
 * 1：登记；2：年检
 */
export enum ChargeItem {
  /**
   * 未知
   */
  UnKnown = 0,
  /**
   * 登记收费
   */
  RegisterCharge = 1,
  /**
   * 年检收费
   */
  AnnualCharge = 2
}

/**
 * 缴费类型
 * 1：免费；2：半价；3：全额
 */
export enum ChargeType {
  /**
   * 未知
   */
  UnKnown = 0,
  /**
   * 免费
   */
  Free = 1,
  /**
   * 半价
   */
  Half = 2,
  /**
   * 全额
   */
  Full = 3,
}

/**
 * 犬类
 */
export enum DogType {
  /**
   * 未知
   */
  UnKnown = 0,
  /**
   * 小型犬
   */
  SmallDogs = 1,
  /**
   * 大型犬
   */
  LargeDogs = 2,
  /**
   * 烈性犬
   */
  FierceDog = 3
}

/**
 * 消息推送业务类型
 */
export enum SignalRType {
  /**
   * 个人养犬登记
   */
  PersonalDogRegistration = 1,
  /**
   * 单位养犬登记
   */
  CompanyDogRegistration = 2,
  /**
   * 年度检验
   */
  AnnualInspection = 3,
  /**
   * 变更登记
   */
  ChangeRegistration = 4,
  /**
   * 补发犬证
   */
  ReplacementCanineCard = 5,
  /**
   * 注销犬证
   */
  CollectionCanineCard = 6,
  /**
   * 吊销
   */
  RevocationCanineCard = 7,
  /**
   * 个人养犬查询
   */
  PersonalDogBreedingEnquire = 8,
  /**
   * 单位养犬查询
   */
  CompanyDogBreedingEnquire = 9,
  /**
   * 工具箱
   */
  ToolBox = 10,
  /**
   * 打印
   */
  Print = 11,
}

/**
 * 业务类型
 */
export enum BusinessType {
  /**
   * 未知
   */
  UnKnown = 0,
  /**
   * 变更登记
   */
  ChangeOfRegistration = 1,
  /**
   * 年度检查
   */
  AnnualInspection = 2,
  /**
   * 补发犬证
   */
  AgainDogCertification = 3,
  /**
   * 注销犬证
   */
  LogoutDogCertification = 4,
  /**
   * 吊销犬证
   */
  RevokeDogCertification = 5
}

/**
 * 经营场所备案分类
 */
export enum CompanyCategory {
  /**
   * 未知
   */
  UnKnown = 0,
  /**
   * 养殖
   */
  Farm = 1,
  /**
   * 美容
   */
  Hairdressing = 2,
  /**
   * 医疗
   */
  Medical = 3,
  /**
   * 其他
   */
  Others = 4
}

export enum HospitalState {
  /**
   * 未知
   */
  UnKnown = 0,
  /**
   * 审核通过
   */
  Pass = 1,
  /**
   * 审核驳回
   */
  Refused = 2,
}

/**
 * 学历
 */
export enum CertificateType {
  初中 = 0,
  高中 = 1,
  专科 = 2,
  本科 = 3,
  硕士 = 4,
  博士 = 5,
  其他 = 6
}

/**
 * 性别
 */
export enum Gender {
  男 = 1,
  女 = 2
}

/**
 * 进出
 */
export enum EnterOrExit {
  进 = 1,
  出 = 2
}

/**
 * 开门结果
 */
export enum OpenResult {
  开门 = 1,
  未开门 = 0
}

/**
 * 设备类型
 */
export enum DeviceType {
  车辆识别摄像机 = 1,
  人脸识别摄像机 = 2,
  人脸识别门禁 = 3,
  网络摄像机 = 4
}
