import { Pipe, PipeTransform } from '@angular/core';
import { DictStorageService } from '../storages/dict-storage.service';
import { KeyValue, SlicePipe } from '@angular/common';
import { Observable, of } from 'rxjs';

@Pipe({
  name: 'dict'
})
export class DictPipe implements PipeTransform {

  constructor(private dictStorageService: DictStorageService) {
  }

  transform(value: any): Observable<Array<KeyValue<string, string>>> {
    if (!value) {
      return of([]);
    }
    return this.dictStorageService.getItems(value);
  }
}
