import { Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';
import { KeyValueParentModel } from '../models/key-value-parent.model';
import { CityStorageService } from '../storages/city-storage.service';

@Pipe({
  name: 'city'
})
export class CityPipe implements PipeTransform {
  constructor(private cityStorageService: CityStorageService) { }

  /**
   * transform
   * @param parentCode 父Code
   * @param exceptCode 排除项
   */
  transform(parentCode?: string, exceptCode?: string)
    : Observable<Array<KeyValueParentModel<string, string, string>>> {
    return this.cityStorageService.getTree(parentCode, exceptCode);
  }
}
