import { Pipe, PipeTransform } from '@angular/core';
import { OrgStorageService } from '../storages/org-storage.service';
import { Observable, of } from 'rxjs';
import { KeyValueParentModel } from '../models/key-value-parent.model';

@Pipe({
  name: 'orgEffective'
})
export class OrgEffectivePipe implements PipeTransform {

  constructor(private orgStorageService: OrgStorageService) { }

  transform(parentId?: string, exceptId?: string, includeId?: string): Observable<Array<KeyValueParentModel<string, string, string>>> {
    return this.orgStorageService.getEffectiveTree(parentId, exceptId, includeId);
  }
}
