<!-- 总容器 -->
<div class="frame" [ngStyle]="{'overflow-y': middleType === 'table'? 'hidden': 'hidden'}">
    <!-- 顶部容器 -->
    <div [hidden]="headerHidden" class="header">
        <ng-content select=".t-page-header"></ng-content>
    </div>

    <!-- 主内容容器 -->
    <div class="middle" [ngStyle]="{'overflow-y': middleType === 'table'? 'hidden': 'scroll'}">
        <!-- 左侧容器 -->
        <div [hidden]="leftHidden" class="left">
            <ng-content select=".t-page-left"></ng-content>
        </div>

        <!-- 列表容器 -->
        <div [hidden]="middleType === 'form'" class="center">
            <!-- 条件区 -->
            <div [hidden]="queryHidden" class="query">
                <ng-content select=".t-page-query"></ng-content>
            </div>
            <!-- 内容区 -->
            <div class="content">
                <ng-content></ng-content>
            </div>
        </div>
        <!-- 表单容器 -->
        <div [hidden]="middleType === 'table'" class="form-center">
            <div class="content" style="height: 100%;">
                <section #body></section>
            </div>
            <!-- 操作区 -->
            <div class="operate">
                <button nz-button nzType="primary" [disabled]="submitDisabled" [nzLoading]="submitLoading"
                    style="margin-right: 16px;" (click)="submitEvent($event)">{{submitLabel}}</button>
                <button nz-button nzType="default" (click)="cancelEvent($event)">{{cancelLabel}}</button>
            </div>
        </div>

        <!-- 右侧容器 -->
        <div [hidden]="rightHidden" class="right">
            <ng-content select=".t-page-right"></ng-content>
        </div>
    </div>

    <!-- 底部容器 -->
    <div [hidden]="footerHidden" class="footer">
        <ng-content select=".t-page-footer"></ng-content>
    </div>
</div>