import { IMqttServiceOptions } from 'ngx-mqtt';
import { environment } from 'src/environments/environment';

export const SETMQ_SERVER_OPTIONS: IMqttServiceOptions = {
    protocol: environment.MQTT.protocol as ('ws' | 'wss'),
    hostname: environment.MQTT.host,
    port: environment.MQTT.port,
    path: environment.MQTT.path,
    username: environment.MQTT.user,
    password: environment.MQTT.password
};
