<!-- 用户图标 -->
<nz-avatar nzSize="small" nzIcon="user"></nz-avatar>

<!-- 登录信息 -->
<a nz-dropdown [nzDropdownMenu]="menu" [nzClickHide]="false">
  {{model?.userName}}
  <i nz-icon nzType="down"></i>
</a>
<nz-dropdown-menu #menu>
  <div style="margin-top: 12px;">
    <ul nz-menu nzSelectable>
      <li nz-menu-item (click)="resetPassword(tplContent)">
        <i nz-icon nzType="edit" style="font-size: 16px; margin-right: 5px;"></i>
        修改密码
      </li>
      <li nz-menu-item (click)="logout()">
        <i nz-icon nzType="export" style="font-size: 16px; margin-right: 5px;"></i>
        退出系统
      </li>
    </ul>
  </div>
</nz-dropdown-menu>

<!-- 修改密码 -->
<ng-template #tplContent>
  <form nz-form [formGroup]="validateForm">
    <div nz-row>
      <div nz-col nzSm="24">
        <nz-form-item nzJustify="start" nzAlign="top">
          <nz-form-label class="form-label-100" nzFor="password" nzRequired>密码</nz-form-label>
          <nz-form-control class="form-control-1" [nzErrorTip]="errorPassword">
            <input nz-input type="password" id="password" formControlName="password" placeholder="密码">
            <ng-template #errorPassword let-control>
              <ng-container *ngIf="control.hasError('required')">
                请输入密码!
              </ng-container>
            </ng-template>
          </nz-form-control>
        </nz-form-item>
      </div>

      <div nz-col nzSm="24">
        <nz-form-item nzJustify="start" nzAlign="top">
          <nz-form-label class="form-label-100" nzFor="newPassword" nzRequired>新密码</nz-form-label>
          <nz-form-control class="form-control-1" [nzErrorTip]="errorNewPassword">
            <input nz-input type="password" id="newPassword" formControlName="newPassword"
              validateEqual="confirmNewPassword" reverse="true" placeholder="新密码">
            <ng-template #errorNewPassword let-control>
              <ng-container *ngIf="control.hasError('required')">
                请输入新密码!
              </ng-container>
              <ng-container *ngIf="control.hasError('pattern')">
                至少包含数字、字母、特殊字符两种以上，长度不小于6位！
              </ng-container>
            </ng-template>
          </nz-form-control>
        </nz-form-item>
      </div>

      <div nz-col nzSm="24">
        <nz-form-item nzJustify="start" nzAlign="top">
          <nz-form-label class="form-label-100" nzFor="confirmNewPassword" nzRequired>确认新密码</nz-form-label>
          <nz-form-control class="form-control-1" [nzErrorTip]="errorConfirmNewPassword">
            <input nz-input type="password" id="confirmNewPassword" formControlName="confirmNewPassword"
              validateEqual="newPassword" placeholder="确认新密码">
            <ng-template #errorConfirmNewPassword let-control>
              <ng-container *ngIf="control.hasError('required')">
                请输入确认新密码!
              </ng-container>
              <ng-container *ngIf="control.hasError('pattern')">
                至少包含数字、字母、特殊字符两种以上，长度不小于6位！
              </ng-container>
              <ng-container *ngIf="!control.hasError('required') && control.hasError('validateEqual')">
                两次输入密码不一致!
              </ng-container>
            </ng-template>
          </nz-form-control>
        </nz-form-item>
      </div>
    </div>
  </form>
</ng-template>
