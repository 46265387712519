import { Injectable } from '@angular/core';
import { LocalStorageService } from 'angular-2-local-storage';
import { UserService } from '../../system/services/user.service';
import { SingleResponse } from '../models/single-response';
import { MenuSearchModel } from '../../system/models/menu-search.model';
import { tap, shareReplay, map, publishReplay, refCount } from 'rxjs/operators';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserResourceStorageService {

  private readonly DICKEY: string = 'USER_RESOURCE';
  private http$: any;

  constructor(
    private userService: UserService,
    private localStorageService: LocalStorageService
  ) { }

  /**
   * 用户资源缓存初始化
   */
  public setStorage(): Observable<any> {
    // 判断是否存在
    if (!this.http$) {
      this.http$ = this.userService.userResource().pipe(
        shareReplay()
        // publishReplay(),
        // refCount()
      );
    }

    const result = this.http$.pipe(
      map((response: SingleResponse<any>) => {
        let data: any;
        if (!response.didError) {
          this.localStorageService.set(this.DICKEY, response.model);
          data = response.model;
        }

        return data;
      })
    );

    return result;
  }

  /**
   * 获取用户资源缓存
   */
  public getStorage(): Observable<any> {
    const result = this.localStorageService.get<any>(this.DICKEY);
    if (!result) {
      // 请求数据
      return this.setStorage();
    }

    // 拉取数据
    return of(result);
  }

  /**
   * 获取登录用户信息
   */
  public getUser(): Observable<any> {
    let result: any = null;

    // 获取缓存资源
    return this.getStorage().pipe(
      map((value: any) => {
        if (value) {
          // 获取字典项
          result = {
            // 用户ID
            userId: value.userId,
            // 用户名
            userName: value.userName,
            // 是否为超级用户
            isSuperAdmin: value.isSuperAdmin,
            // 用户行版本
            userRowVersion: value.userRowVersion,
            // 机构ID
            organizationId: value.organizationId,
            // 机构名
            organizationName: value.organizationName,
            // 机构编码
            organizationCode: value.organizationCode,
            // 大华机构编码
            orgCode: value.orgCode
          };
        }

        return result;
      })
    );
  }

  /**
   * 获取登录用户的角色
   */
  public getUserRole(): Observable<any> {
    let result: any = null;
    // 获取缓存资源
    return this.getStorage().pipe(
      map((value: any) => {
        if (value && value.roleKeyValues) {
          result = value.roleKeyValues;
        }
        return result;
      })
    );
  }

  /**
   * 判断是否超级管理员
   */
  public getIsAdmin(): Observable<boolean> {
    let result: any = false;
    // 获取缓存资源
    return this.getStorage().pipe(
      map((value: any) => {
        if (value) {
          result = value.isSuperAdmin === 1;
        }
        return result;
      })
    );
  }

  /**
   * 获取用户菜单资源
   */
  public getMenu(): Observable<Array<MenuSearchModel>> {
    // 获取缓存资源
    return this.getStorage().pipe(
      map((value) => {
        return value.menus;
      })
    );
  }

  /**
   * 构造菜单树
   * @param id 父节点ID
   */
  getMenuTree(id?: string): Observable<Array<any>> {
    return this.getMenu().pipe(
      map((value) => {
        if (value) {
          return this.recurrence(value, id);
        }

      })
    );
  }

  /**
   * 递归获取菜单树
   * @param sources 菜单列表
   * @param id 父节点ID
   */
  recurrence(sources: Array<any>, id?: string): Array<any> {

    let result: any = [];

    if (!sources) {
      return [];
    }

    const list = [...sources];

    const func = (parentId?: string): Array<any> => {

      if (!parentId) {
        parentId = null;
      }

      const items: any = list
        .filter(x => x.parentId === parentId)
        .sort((x, y) => x.order - y.order)
        .map(x => ({
          id: x.id,
          router: x.router,
          name: x.menuName,
          icon: x.icon ? x.icon : 'audit',
          open: false,
          selected: false
        }));

      if (items) {
        items.forEach(x => {
          const children = func(x.id);
          if (children && children.length > 0) {
            x.children = children;
          }
        });
      }

      return items;
    };

    const temp = func(id);

    // 逻辑处理是否将当前项追加
    const current = sources.find(x => !!id && x.id === id);
    if (current) {
      result.unshift({
        id: current.id,
        router: current.router,
        name: current.menuName,
        icon: current.icon ? current.icon : 'audit',
        open: false,
        selected: false,
        children: temp
      });
    } else {
      result = temp;
    }

    return result;
  }

  /**
   * 清空缓存
   */
  clearStorage(): void {
    if (this.http$) {
      this.http$ = null;
    }

    this.localStorageService.remove(this.DICKEY);
  }

}
