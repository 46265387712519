<!-- 通知提醒 -->
<a nz-dropdown [nzDropdownMenu]="menu" [nzClickHide]="true" nzTrigger="hover">
  <nz-badge nzShowZero="true" [nzCount]="count" style="font-size: 16px;"
    [nzStyle]="{ height: '16px', lineHeight: '16px' }">
    <i nz-icon nzType="bell"></i>
  </nz-badge>
</a>

<nz-dropdown-menu #menu="nzDropdownMenu">
  <nz-card nz-menu nzSize="small" class="notice-card"
    [nzBodyStyle]="{ width: '320px', padding: '0', fontSize: '12px' }">
    <nz-tabset nzSize="small" nzShowPagination="false" [nzTabBarGutter]="0">
      <nz-tab [nzTitle]="noticeTitle">
        <div>
          <nz-list [nzDataSource]="noticeList" [nzRenderItem]="defaultItem" [nzLoading]="initLoading"
            style="height: 200px; overflow-y: auto;">
            <ng-template #defaultItem let-item>
              <nz-list-item style="cursor: pointer;">
                <div style="flex: 1;" (click)="reminderDealClick($event,item.id)">
                  {{ item?.caption }}
                </div>
                <div style="padding: 0 10px;">
                  <button nz-button nzType="primary" [nzSize]="'small'" (click)="details(item.id)">
                    <label style="font-size: 12px;">详情</label>
                  </button>
                </div>
              </nz-list-item>
            </ng-template>
          </nz-list>

          <div style="display: flex; border-top: 1px solid #ccc;">
            <a *ngIf="noticeList.length>0"
              style="flex: 1; height: 30px; line-height: 30px; text-align: center; border-right: 1px solid #ccc;color: dimgray"
              title="清空" (click)="reminderDealClick($event,noticeList)">清空</a>
            <a *ngIf="noticeList.length <= 0"
              style="flex: 1; height: 30px; line-height: 30px; text-align: center; border-right: 1px solid #ccc;color:darkgray"
              title="清空">清空</a>
            <a style="flex: 1; height: 30px; line-height: 30px; text-align: center;color: dimgray" title="更多">更多</a>
          </div>
        </div>
      </nz-tab>
      <nz-tab [nzTitle]="messageTitle">
        <div>
          <nz-list [nzDataSource]="messageList" [nzRenderItem]="messageItem" [nzLoading]="initLoading"
            style="height: 200px; overflow-y: auto;">
            <ng-template #messageItem let-item>
              <nz-list-item style="cursor: pointer;">
                <div style="flex: 1;" (click)="reminderDealClick($event,item.id)">
                  {{ item?.caption }}
                </div>
                <div style="padding: 0 10px;">
                  <button nz-button nzType="primary" [nzSize]="'small'" (click)="details(item.id)">
                    <label style="font-size: 12px;">详情</label>
                  </button>
                </div>
              </nz-list-item>
            </ng-template>
          </nz-list>

          <div style="display: flex; border-top: 1px solid #ccc;">
            <a *ngIf="messageList.length>0"
              style="flex: 1; height: 30px; line-height: 30px; text-align: center; border-right: 1px solid #ccc;color: dimgray"
              title="清空" (click)="reminderDealClick($event,messageList)">清空</a>
            <a *ngIf="messageList.length<=0"
              style="flex: 1; height: 30px; line-height: 30px; text-align: center; border-right: 1px solid #ccc;color:darkgray"
              title="清空">清空</a>
            <a style="flex: 1; height: 30px; line-height: 30px; text-align: center;color: dimgray" title="更多">更多</a>
          </div>
        </div>
      </nz-tab>
      <nz-tab [nzTitle]="toBeDoneTitle">
        <div>
          <nz-list [nzDataSource]="toBeDoneList" [nzRenderItem]="toBeDoneItem" [nzLoading]="initLoading"
            style="height: 200px; overflow-y: auto;">
            <ng-template #toBeDoneItem let-item>
              <nz-list-item style="cursor: pointer;">
                <div style="flex: 1;" (click)="reminderDealClick($event,item.id)">
                  {{ item?.caption }}
                </div>
                <div style="padding: 0 10px;">
                  <button nz-button nzType="primary" [nzSize]="'small'" (click)="details(item.id)">
                    <label style="font-size: 12px;">详情</label>
                  </button>
                </div>
              </nz-list-item>
            </ng-template>
          </nz-list>

          <div style="display: flex; border-top: 1px solid #ccc;">
            <a *ngIf="toBeDoneList.length>0"
              style="flex: 1; height: 30px; line-height: 30px; text-align: center; border-right: 1px solid #ccc;color: dimgray"
              title="清空" (click)="reminderDealClick($event,toBeDoneList)">清空</a>
            <a *ngIf="toBeDoneList.length <= 0"
              style="flex: 1; height: 30px; line-height: 30px; text-align: center; border-right: 1px solid #ccc;color:darkgray"
              title="清空">清空</a>
            <a style="flex: 1; height: 30px; line-height: 30px; text-align: center;color: dimgray" title="更多">更多</a>
          </div>
        </div>
      </nz-tab>
    </nz-tabset>
  </nz-card>
</nz-dropdown-menu>

<nz-modal [nzVisible]="count > 0" nzTitle="站内信息提醒" [nzClosable]="false" nzOkText="确定" nzCancelText="关闭"
  (nzOnOk)="cancelEvent()" (nzOnCancel)="cancelEvent()">
  <nz-card nz-menu nzSize="small" style="margin-top: 12px; padding: 0;"
    [nzBodyStyle]="{ width: 'auto', padding: '0 0 0 20px', fontSize: '12px' }">
    <nz-tabset nzSize="small" nzShowPagination="false" [nzTabBarGutter]="0">
      <nz-tab [nzTitle]="noticeTitle">
        <div style="margin-right: 32px;">
          <nz-list [nzDataSource]="noticeList" [nzRenderItem]="defaultItem" [nzLoading]="initLoading"
            style="height: 200px; overflow-y: auto;">
            <ng-template #defaultItem let-item>
              <nz-list-item style="cursor: pointer;">
                <div style="flex: 1;" (click)="reminderDealClick($event,item.id)">
                  {{ item?.caption }}
                </div>
                <div style="padding: 0 10px;">
                  <button nz-button nzType="primary" [nzSize]="'small'" (click)="details(item.id)">
                    <label style="font-size: 12px;">详情</label>
                  </button>
                </div>
              </nz-list-item>
            </ng-template>
          </nz-list>

          <div style="display: flex; border-top: 1px solid #ccc;">
            <a *ngIf="noticeList.length>0"
              style="flex: 1; height: 30px; line-height: 30px; text-align: center; border-right: 1px solid #ccc;color: dimgray"
              title="清空" (click)="reminderDealClick($event,noticeList)">清空</a>
            <a *ngIf="noticeList.length <= 0"
              style="flex: 1; height: 30px; line-height: 30px; text-align: center; border-right: 1px solid #ccc;color:darkgray"
              title="清空">清空</a>
            <a style="flex: 1; height: 30px; line-height: 30px; text-align: center;color: dimgray" title="更多">更多</a>
          </div>
        </div>
      </nz-tab>
      <nz-tab [nzTitle]="messageTitle">
        <div style="margin-right: 32px;">
          <nz-list [nzDataSource]="messageList" [nzRenderItem]="messageItem" [nzLoading]="initLoading"
            style="height: 200px; overflow-y: auto;">
            <ng-template #messageItem let-item>
              <nz-list-item style="cursor: pointer;">
                <div style="flex: 1;" (click)="reminderDealClick($event,item.id)">
                  {{ item?.caption }}
                </div>
                <div style="padding: 0 10px;">
                  <button nz-button nzType="primary" [nzSize]="'small'" (click)="details(item.id)">
                    <label style="font-size: 12px;">详情</label>
                  </button>
                </div>
              </nz-list-item>
            </ng-template>
          </nz-list>

          <div style="display: flex; border-top: 1px solid #ccc;">
            <a *ngIf="messageList.length>0"
              style="flex: 1; height: 30px; line-height: 30px; text-align: center; border-right: 1px solid #ccc;color: dimgray"
              title="清空" (click)="reminderDealClick($event,messageList)">清空</a>
            <a *ngIf="messageList.length<=0"
              style="flex: 1; height: 30px; line-height: 30px; text-align: center; border-right: 1px solid #ccc;color:darkgray"
              title="清空">清空</a>
            <a style="flex: 1; height: 30px; line-height: 30px; text-align: center;color: dimgray" title="更多">更多</a>
          </div>
        </div>
      </nz-tab>
      <nz-tab [nzTitle]="toBeDoneTitle">
        <div style="margin-right: 32px;">
          <nz-list [nzDataSource]="toBeDoneList" [nzRenderItem]="toBeDoneItem" [nzLoading]="initLoading"
            style="height: 200px; overflow-y: auto;">
            <ng-template #toBeDoneItem let-item>
              <nz-list-item style="cursor: pointer;">
                <div style="flex: 1;" (click)="reminderDealClick($event,item.id)">
                  {{ item?.caption }}
                </div>
                <div style="padding: 0 10px;">
                  <button nz-button nzType="primary" [nzSize]="'small'" (click)="details(item.id)">
                    <label style="font-size: 12px;">详情</label>
                  </button>
                </div>
              </nz-list-item>
            </ng-template>
          </nz-list>

          <div style="display: flex; border-top: 1px solid #ccc;">
            <a *ngIf="toBeDoneList.length>0"
              style="flex: 1; height: 30px; line-height: 30px; text-align: center; border-right: 1px solid #ccc;color: dimgray"
              title="清空" (click)="reminderDealClick($event,toBeDoneList)">清空</a>
            <a *ngIf="toBeDoneList.length <= 0"
              style="flex: 1; height: 30px; line-height: 30px; text-align: center; border-right: 1px solid #ccc;color:darkgray"
              title="清空">清空</a>
            <a style="flex: 1; height: 30px; line-height: 30px; text-align: center;color: dimgray" title="更多">更多</a>
          </div>
        </div>
      </nz-tab>
    </nz-tabset>
  </nz-card>
</nz-modal>