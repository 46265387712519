import { Injectable } from '@angular/core';
import { BaseService } from '../../shared/base.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ListResponse } from '../../shared/models/list-response';
import { RemindSearchModel } from '../models/remind-search.model';
import { PagedResponse } from '../../shared/models/paged-response';
import { RemindPagedParameter } from '../models/remind-paged.parameter';
import { SingleResponse } from '../../shared/models/single-response';

@Injectable({
  providedIn: 'root'
})
export class SmsService extends BaseService {
  private baseUrl = `${this.apiUrlBase}/api/sms`;

  constructor(
    private http: HttpClient
  ) {
    super();
  }

  /**
   * 获取登录手机验证码
   */
  getSmsCode(phone: number): Observable<SingleResponse<any>> {
    // 请求地址
    const url = `${this.baseUrl}/loginSmsCode?phone=${phone}`;
    return this.http.get<SingleResponse<any>>(url);
  }
}
