import { Component, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators
} from '@angular/forms';
import { AuthService } from '../../../frame/services/auth.service';
import { NzMessageService } from 'ng-zorro-antd/message';
import { Router, ActivatedRoute } from '@angular/router';
import { ValidationErrorHandler } from '../../../shared/validation-error-handler';
import { UserResourceStorageService } from '../../../shared/storages/user-resource-storage.service';
import { mergeMap, map, publishReplay, refCount } from 'rxjs/operators';
import { SingleResponse } from '../../../shared/models/single-response';
import { of } from 'rxjs';
import { SmsService } from '../../services/sms.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.less']
})
export class LoginComponent implements OnInit {

  public validateForm: FormGroup;
  public validateCodeForm: FormGroup;
  public passwordVisible = false;
  public isSpinning = false;
  private returnUrl: string;
  public step = 2;

  // 按钮文字
  public smsBtnText = '验证码';
  // 是否正在倒计时
  public smsStatus = false;
  /**
   * 倒计时句柄
   */
  private countdownTimer: any;

  /**
   * 构造器
   * @param fb 表单
   * @param router 路由
   * @param router 动态路由
   * @param message 消息模态框
   * @param authService 授权服务
   */
  constructor(
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private message: NzMessageService,
    private authService: AuthService,
    private userResourceStorageService: UserResourceStorageService,
    private smsService: SmsService,
  ) { }

  /**
   * 初始化
   */
  ngOnInit(): void {
    this.validateForm = this.fb.group({
      userName: [null, [Validators.required, Validators.maxLength(20)]],
      password: [null, [Validators.required]],
      remember: [false]
    });

    this.validateCodeForm = this.fb.group({
      phone: [null, [Validators.required, Validators.pattern(/^1[3456789]\d{9}$/), Validators.maxLength(11)]],
      smsCode: [null, [Validators.required, Validators.pattern(/^\d{6}$/), Validators.maxLength(6)]],
      remember: [false]
    });
    // reset login status
    this.authService.clearResource();

    // get return url from route parameters or default to '/'
    this.returnUrl = this.route.snapshot.queryParams.returnUrl || '/';
  }

  /**
   * 切换登录方式
   */
  phoneSignIn($event: any): void {
    this.step = $event;
  }
  /**
   * 切换登录方式
   */
  adminSignIn($event: any): void {
    this.step = $event;
  }

  /**
   * 生成短信验证码变更事件
   * @param _event 参数
   */
  onSmsCode($event: any): void {

    // 短信服务
    if ($event.phone) {
      // 倒计时
      this.smsCodeTimer();
      this.smsService.getSmsCode($event.phone).subscribe(response => {

        if (!response.didError) {
          this.message.success('验证码已发送！');
        } else {
          this.message.error('验证码发送失败！');
        }
      }, error => {
        clearInterval(this.countdownTimer);

        this.countdownTimer = undefined;
        this.smsStatus = false;
        this.smsBtnText = `验证码`;
      });
    } else {
      this.message.error('请输入手机号码！');
    }
  }

  /**
   * 短信验证码倒计时
   */
  smsCodeTimer(): void {
    try {

      if (!this.countdownTimer) {
        this.smsStatus = true;
        // 初始化倒计时数字
        let smsNum = 60;
        this.smsBtnText = `${smsNum} 秒`;

        this.countdownTimer = setInterval(() => {
          smsNum = smsNum - 1;

          if (smsNum <= 0 && this.countdownTimer) {
            clearInterval(this.countdownTimer);

            this.countdownTimer = undefined;
            this.smsStatus = false;
            this.smsBtnText = `验证码`;
          } else {
            this.smsBtnText = `${smsNum} 秒`;
          }
        }, 1000);
      }
    } catch {
      if (this.countdownTimer) {
        clearInterval(this.countdownTimer);

        this.countdownTimer = undefined;
        this.smsStatus = false;
        this.smsBtnText = `验证码`;
      }
    }
  }

  /**
   * 账号密码登录提交
   * @param $event 句柄
   * @param model 登录模型
   */
  submit($event: any, model: any): void {
    // 循环验证表单
    for (const index in this.validateForm.controls) {
      if (index) {
        this.validateForm.controls[index].markAsDirty();
        this.validateForm.controls[index].updateValueAndValidity();
      }
    }

    if (this.validateForm.valid) {

      // 登录
      this.authService.login(model, 3).pipe(
        mergeMap(
          (response: SingleResponse<any>, index: number) => {
            const result = of(null);

            if (response && !response.didError) {
              // 判断用户是否登录成功
              if (!response.model || !response.model.access_token) {
                this.message.error('用户登录失败，用户名或密码错误！');
                return result;
              }

              try {
                // 是否加载等待框
                this.isSpinning = true;

                // 获取用户菜单树
                return this.userResourceStorageService.getMenuTree();
              } catch (ex) {
                // 是否加载等待框
                this.isSpinning = false;
                return result;
              }
            } else {
              // 是否加载等待框
              this.isSpinning = false;
              this.message.error('用户登录失败，请检查用户名或密码是否输入有误！');
              return result;
            }
          }
        )
      ).subscribe((menus: any) => {
        if (menus) {
          let returnUrl = this.returnUrl;

          if (this.returnUrl === '/' && menus && menus[0] && menus[0].children && menus[0].children[0]) {
            returnUrl = menus[0].children[0].router;
          }
          // 登录成功，页面跳转
          this.router.navigate([returnUrl]);
        }

        // 是否加载等待框
        this.isSpinning = false;
      },
        validationResult => {
          // 是否加载等待框
          this.isSpinning = false;
          // 异常信息
          ValidationErrorHandler.handleFormValidationErrors(this.validateForm, validationResult);
          // 更新表单验证
          this.validateForm.updateValueAndValidity();
        }
      );
    }
  }

  /**
   * 手机验证码登录提交
   * @param $event 句柄
   * @param model 登录模型
   */
  submitCode($event: any, model: any): void {
    // 循环验证表单
    for (const index in this.validateCodeForm.controls) {
      if (index) {
        this.validateCodeForm.controls[index].markAsDirty();
        this.validateCodeForm.controls[index].updateValueAndValidity();
      }
    }

    if (this.validateCodeForm.valid) {

      // 登录
      this.authService.login(model, 6).pipe(
        mergeMap(
          (response: SingleResponse<any>, index: number) => {
            const result = of(null);

            if (response && !response.didError) {
              // 判断用户是否登录成功
              if (!response.model || !response.model.access_token) {
                this.message.error('用户登录失败，验证码错误！');
                return result;
              }

              try {
                // 是否加载等待框
                this.isSpinning = true;

                // 获取用户菜单树
                return this.userResourceStorageService.getMenuTree();
              } catch (ex) {
                // 是否加载等待框
                this.isSpinning = false;
                return result;
              }
            } else {
              // 是否加载等待框
              this.isSpinning = false;
              this.message.error('用户登录失败，请检查手机号码是否输入有误！');
              return result;
            }
          }
        )
      ).subscribe((menus: any) => {
        if (menus) {
          let returnUrl = this.returnUrl;

          if (this.returnUrl === '/' && menus && menus[0] && menus[0].children && menus[0].children[0]) {
            returnUrl = menus[0].children[0].router;
          }
          // 登录成功，页面跳转
          this.router.navigate([returnUrl]);
        }

        // 是否加载等待框
        this.isSpinning = false;
      },
        validationResult => {
          // 是否加载等待框
          this.isSpinning = false;
          // 异常信息
          ValidationErrorHandler.handleFormValidationErrors(this.validateCodeForm, validationResult);
          // 更新表单验证
          this.validateCodeForm.updateValueAndValidity();
        }
      );
    }
  }
}
