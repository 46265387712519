import { Injectable } from '@angular/core';
import { observable, of, Observable } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { catchError, map, tap } from 'rxjs/operators';

import { PagedResponse } from '../../shared/models/paged-response';
import { ListResponse } from '../../shared/models/list-response';
import { SingleResponse } from '../../shared/models/single-response';
import { NocontentResponse } from '../../shared/models/nocontent-response';

import { DictModel } from '../models/dict.model';
import { DictParameter } from '../models/dict-parameter';
import { DictSearchModel } from '../models/dict-search.model';

import { BaseService } from '../../shared/base.service';
import { KeyValueParentModel } from '../../shared/models/key-value-parent.model';
import { KeyValueModel } from '../../shared/models/key-value.model';

@Injectable({
  providedIn: 'root'
})
export class DictService extends BaseService {
  // URL to web api
  private apUrl = `${this.apiUrlBase}/api/dictionary`;

  constructor(
    private http: HttpClient
  ) {
    super();
  }

  /**
   * 获取所有字典项
   */
  getAll(): Observable<ListResponse<KeyValueParentModel<string, string, string>>> {
    const url = `${this.apUrl}/getAll`;
    // 参数处理
    return this.http.get<ListResponse<KeyValueParentModel<string, string, string>>>(url);
  }

  /**
   * 获取机构分页集合
   * @param parameter 查询参数
   */
  paged(parameter: DictParameter)
    : Observable<PagedResponse<DictSearchModel>> {
    // 参数处理
    const params = this.removeEmptyProps(parameter);
    return this.http.get<PagedResponse<DictSearchModel>>(this.apUrl, { params });
  }

  /**
   * 根据字典分类获取分类字典项
   */
  getKeyValue(code: string): Observable<ListResponse<KeyValueModel<string, string>>> {
    const url = `${this.apUrl}/getkeyvalue/${code}`;
    return this.http.get<ListResponse<KeyValueModel<string, string>>>(url);
  }

  /**
   * 获取机构详情
   * @param id 机构ID
   */
  details(id: string): Observable<SingleResponse<DictModel>> {
    const url = `${this.apUrl}/${id}`;
    return this.http.get<SingleResponse<DictModel>>(url);
  }

  /**
   * 新增机构
   * @param inputModel 机构实体
   */
  add(inputModel: DictModel): Observable<SingleResponse<DictModel>> {
    return this.http.post<SingleResponse<DictModel>>(this.apUrl, inputModel, this.httpOptions);
  }

  /**
   * 更新机构
   * @param inputModel 机构实体
   */
  update(inputModel: DictModel): Observable<NocontentResponse> {
    // 请求url
    const url = `${this.apUrl}/${inputModel.id}`;
    return this.http.put<NocontentResponse>(url, inputModel, this.httpOptions);
  }

  /**
   * 撤销删除机构
   * @param id ID
   */
  undelete(id: string): Observable<NocontentResponse> {
    const url = `${this.apUrl}/undelete/${id}`;
    return this.http.delete<NocontentResponse>(url, this.httpOptions);
  }

  /**
   * 删除机构
   * @param id 机构ID
   */
  delete(id: string): Observable<NocontentResponse> {
    const url = `${this.apUrl}/${id}`;
    return this.http.delete<NocontentResponse>(url, this.httpOptions);
  }
}
