import { Pipe, PipeTransform } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { UserResourceStorageService } from '../storages/user-resource-storage.service';

@Pipe({
    name: 'orgCodeSplit'
})
export class OrgCodeSplitPipe implements PipeTransform {

    constructor(
        private userStorage: UserResourceStorageService
    ) { }

    transform(value: any): Observable<Array<string>> {
        if (!value) {
            return of([]);
        }
        return this.userStorage.getUser().pipe(
            map(userResource => {
                let orgCodes = [];
                if (userResource) {
                    if (value) {
                        const splitOrgCodes = value.split('$');
                        splitOrgCodes.forEach((element, codeIndex) => {
                            if (codeIndex > 0) {
                                orgCodes.push(`${orgCodes[orgCodes.length - 1]}$${element}`);
                            } else {
                                orgCodes.push(`${element}`);
                            }
                        });
                    }
                    const index = orgCodes.findIndex(x => x === userResource.organizationCode);
                    orgCodes = orgCodes.slice(index);
                }

                return orgCodes;
            }));
    }
}



