import { NgModule } from '@angular/core';
import { CommonModule, registerLocaleData } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';

import zh from '@angular/common/locales/zh';
registerLocaleData(zh);

import { NZ_I18N, zh_CN } from 'ng-zorro-antd/i18n';

import { NzFormModule } from 'ng-zorro-antd/form';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzInputNumberModule } from 'ng-zorro-antd/input-number';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzCascaderModule } from 'ng-zorro-antd/cascader';
import { NzUploadModule } from 'ng-zorro-antd/upload';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzStatisticModule } from 'ng-zorro-antd/statistic';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzListModule } from 'ng-zorro-antd/list';
import { NzMenuModule } from 'ng-zorro-antd/menu';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzBadgeModule } from 'ng-zorro-antd/badge';
import { NzAvatarModule } from 'ng-zorro-antd/avatar';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { NzSliderModule } from 'ng-zorro-antd/slider';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzSpaceModule } from 'ng-zorro-antd/space';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzDividerModule } from 'ng-zorro-antd/divider';

import { PipesModule } from '../shared/pipes/pipes.module';
import { ValidatorsModule } from '../shared/validators/validators.module';
import { FrameRoutingModule } from './frame-routing.module';

import { JwtInterceptor } from '../shared/jwt.interceptor';
import { ErrorInterceptor } from '../shared/error.interceptor';

import { MqttModule } from 'ngx-mqtt';
import { SETMQ_SERVER_OPTIONS } from '../setmq_server_options';

import { LoginComponent } from './pages/login/login.component';
import { HomeComponent } from './pages/home/home.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { NavMenuComponent } from './components/nav-menu/nav-menu.component';
import { NavHeaderComponent } from './components/nav-header/nav-header.component';
import { NavNoticeComponent } from './components/nav-notice/nav-notice.component';
import { NavUserComponent } from './components/nav-user/nav-user.component';
import { NoPermissionComponent } from './pages/no-permission/no-permission.component';

import { BasisModule } from '../basis/basis.module';

import { IconDefinition } from '@ant-design/icons-angular';
import { MenuFoldOutline, DownloadOutline, FundProjectionScreenOutline,FullscreenOutline,DashboardOutline,ProjectOutline,SelectOutline, LaptopOutline, BellOutline, ExportOutline, UserOutline, PlusSquareOutline, MinusSquareOutline, PlusCircleOutline,  } from '@ant-design/icons-angular/icons';
const icons: IconDefinition[] = [ MenuFoldOutline, DownloadOutline, FundProjectionScreenOutline,FullscreenOutline,DashboardOutline,ProjectOutline,SelectOutline, LaptopOutline, BellOutline, ExportOutline, UserOutline, PlusSquareOutline, MinusSquareOutline, PlusCircleOutline, ];

@NgModule({
  declarations: [
    LoginComponent,
    HomeComponent,
    NotFoundComponent,
    NoPermissionComponent,
    NavMenuComponent,
    NavHeaderComponent,
    NavNoticeComponent,
    NavUserComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    // ng-zorro
    NzFormModule,
    NzGridModule,
    NzInputNumberModule,
    NzInputModule,
    NzSelectModule,
    NzDatePickerModule,
    NzCascaderModule,
    NzUploadModule,
    NzButtonModule,
    NzCardModule,
    NzTabsModule,
    NzTableModule,
    NzToolTipModule,
    NzDropDownModule,
    NzStatisticModule,
    NzSpinModule,
    NzListModule,
    NzMenuModule,
    NzIconModule,
    NzBadgeModule,
    NzAvatarModule,
    NzLayoutModule,
    NzSliderModule,
    NzCheckboxModule,
    NzSpaceModule,
    NzModalModule,
    NzDividerModule,
    NzIconModule.forRoot(icons),
    // other
    MqttModule.forRoot(SETMQ_SERVER_OPTIONS),
    ValidatorsModule.forRoot(),
    PipesModule.forRoot(),
    BasisModule.forRoot(),
    FrameRoutingModule,
  ],
  providers: [
    { provide: NZ_I18N, useValue: zh_CN },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
  ]
})
export class FrameModule { }
