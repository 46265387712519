import { Component, OnInit, OnChanges, Input, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.less']
})
export class IconComponent implements OnInit, OnChanges {
  @Input() iconName: string;
  @Input() width = '100%';
  @Input() height = '100%';

  public imageSrc: string = null;

  constructor() { }

  ngOnInit(): void {
    this.initIconName(this.iconName);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.iconName && changes.iconName.currentValue) {
      this.initIconName(this.iconName);
    }
  }

  initIconName(iconName: string): void {
    if (iconName) {
      const url = `/assets/images/icons/${this.iconName}.svg`;
      this.imageSrc = url;
    }
  }
}
