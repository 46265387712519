import { Injectable } from '@angular/core';
import { observable, of, Observable } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { catchError, map, tap } from 'rxjs/operators';

import { BaseService } from '../../shared/base.service';

import { PagedResponse } from '../../shared/models/paged-response';
import { ListResponse } from '../../shared/models/list-response';
import { SingleResponse } from '../../shared/models/single-response';
import { NocontentResponse } from '../../shared/models/nocontent-response';

import { OrgModel } from '../models/org.model';
import { OrgParameter } from '../models/org-parameter';
import { OrgSearchModel } from '../models/org-search.model';
import { LabelValueModel } from '../../shared/models/label-value.model';
import { OrgDetailsModel } from '../models/org-details.model';
import { KeyValueParentModel } from '../../shared/models/key-value-parent.model';
import { ChildNodeInput } from '../models/childnode-input.model';
import { BuildingInputModel } from 'src/app/business/models/building-input.model';
import { BuildingDetailsModel } from 'src/app/business/models/building-details.model';
import { TreeDeleteModel } from '../models/treedelete.model';

@Injectable({
  providedIn: 'root'
})
export class TreenodeService extends BaseService {
  // URL to web api
  private apiUrl = `${this.apiUrlBase}/api/treeNode`;

  constructor(
    private http: HttpClient
  ) {
    super();
  }

  /**
   * 获取机构分页集合
   * @param parameter 查询参数
   */
  getChildNode(parameter?: ChildNodeInput): Observable<ListResponse<any>> {
    // 参数处理
    const url = `${this.apiUrl}/getChildNode`;
    const params = this.removeEmptyProps(parameter);
    return this.http.get<ListResponse<any>>(url, { params });
  }

  /**
   * 获取所有机构树结构
   * @param exceptId 排除部分不参与构造的节点
   */
  getTree(): Observable<ListResponse<any>> {
    const url = `${this.apiUrl}`;

    return this.http.get<ListResponse<any>>(url);
  }

  /**
   * 新增机构
   * @param inputModel 机构实体
   */
  add(inputModel: BuildingInputModel): Observable<SingleResponse<BuildingDetailsModel>> {
    return this.http.post<SingleResponse<BuildingDetailsModel>>(this.apiUrl, inputModel, this.httpOptions);
  }

  /**
   * 删除用户
   * @param id 用户ID
   */
  delete(inputModel: TreeDeleteModel): Observable<SingleResponse<BuildingDetailsModel>> {
    const url = `${this.apiUrl}/${inputModel.orgCode}`;
    return this.http.post<SingleResponse<BuildingDetailsModel>>(url, inputModel, this.httpOptions);
  }

  /**
   * 根据上级编码查询子集合
   * @param parameter 查询参数
   * @param level 深度
   */
  public getByParentCode(parameter?: ChildNodeInput, level?: number): Observable<Array<any>> {
    return this.getChildNode(parameter)
      .pipe(map(response => {
        const result: Array<any> = [];
        if (response.model) {
          result.push(
            ...response.model.map(x => (
              {
                checked: false,
                expandable: true,
                id: x.key,
                label: x.title,
                level: level ? level : 0,
                disabled: false,
                loading: false,
                isLeaf: x.isLeaf,
                type: x.type,
                code: x.code,
              }
            ))
          );
        }

        return result;
      }));
  }
}
