import { AfterViewInit, Component, EventEmitter, Input, NgZone, OnDestroy, OnInit, Output } from '@angular/core';
import { CollectionViewer, DataSource, SelectionChange, SelectionModel } from '@angular/cdk/collections';
import { FlatTreeControl, TreeControl } from '@angular/cdk/tree';
import { BehaviorSubject, merge, Observable, of, Subject, Subscription } from 'rxjs';
import { delay, map, mergeMap, tap } from 'rxjs/operators';

import { TreenodeService } from '../../../system/services/treenode.service';
import { InjectorInstance } from '../../../../app/app.module';
import { ChildNodeInput } from 'src/app/system/models/childnode-input.model';
import { OrgType } from 'src/app/shared/models/enum';
import { OrgCodeSplitPipe } from 'src/app/shared/pipes/org-code-split.pipe';

@Component({
  selector: 'app-org-tree-edit',
  templateUrl: './org-tree-edit.component.html',
  styleUrls: ['./org-tree-edit.component.less'],
  providers: [
    OrgCodeSplitPipe
  ]
})
export class OrgTreeEditComponent implements OnInit, AfterViewInit, OnDestroy {

  @Input() leafType: OrgType = 0;
  @Output() selectedChange: EventEmitter<FlatNode> = new EventEmitter<FlatNode>();
  @Output() addClick: EventEmitter<FlatNode> = new EventEmitter<FlatNode>();
  @Output() editClick: EventEmitter<FlatNode> = new EventEmitter<FlatNode>();
  @Output() deleteClick: EventEmitter<FlatNode> = new EventEmitter<FlatNode>();

  private defaultSelectedCode: string;
  // 初始化发射器
  private initSubject: Subject<boolean> = new Subject<boolean>();
  private childrenObservable: Subscription;

  public treeControl: FlatTreeControl<FlatNode> = new FlatTreeControl<FlatNode>(
    node => node.level,
    node => node.expandable
  );

  public showLeafIcon = false;
  // 单选
  public selectListSelection = new SelectionModel<FlatNode>(false);
  public dataSource: DynamicDatasource;

  constructor(
    private orgCodeSplitPipe: OrgCodeSplitPipe,
    private treeNodeService: TreenodeService
  ) { }

  ngOnInit(): void {
    this.selectListSelection.changed.subscribe(response => {
      if (response) {
        const added = response.added;
        if (added && added.length) {
          this.selectedChange.emit(response.added[0]);
        } else {
          this.selectedChange.emit(null);
        }
      }

      // 取消节点监听
      if (this.childrenObservable) {
        this.childrenObservable.unsubscribe();
      }
    });

    // 加载列表数据
    this.loadData();
  }

  ngAfterViewInit(): void {
    // 监听数据变化
    this.initSubject.subscribe(response => {
      if (response) {
        // 设置选中值
        this.setSelectedInit(this.defaultSelectedCode);
      }
    });
  }

  ngOnDestroy(): void {
    if (this.childrenObservable) {
      this.childrenObservable.unsubscribe();
    }
  }
  /**
   * 查询框模型变更事件
   * @param event 当前值
   */
  searchModelChange(event: string): void {
    if (event) {

    } else {

    }
  }

  /**
   * 新增节点点击事件
   * @param node 参数
   */
  addNewNode(node?: FlatNode): void {
    this.addClick.emit(node);
  }

  /**
   * 编辑节点点击事件
   * @param event 参数
   */
  editNode(node: FlatNode): void {
    this.editClick.emit(node);
  }

  /**
   * 删除节点点击事件
   * @param event 参数
   */
  deleteNode(node: FlatNode): void {
    this.deleteClick.emit(node);
  }

  hasChild = (_: number, node: FlatNode) => node.expandable;

  /**
   * 根据节点名称获取节点
   * @param id 标识
   * @returns 节点
   */
  getNode(code: string): FlatNode | null {
    return this.treeControl.dataNodes.find(n => n.code === code) || null;
  }

  /**
   * 加载数据
   * @param selectedCode 选址节点编码
   */
  public loadData(selectedCode?: string): void {
    if (this.childrenObservable) {
      this.childrenObservable.unsubscribe();
    }

    // 加载列表数据
    this.treeNodeService
      .getByParentCode(new ChildNodeInput({ leafType: this.leafType }))
      .subscribe(response => {
        this.defaultSelectedCode = selectedCode ? selectedCode : response[0].code;
        this.dataSource = new DynamicDatasource(this.treeControl, this.leafType, response);
        this.initSubject.next(true);
      }, error => {
      }, () => {

      });
  }

  /**
   * 设置选中
   * @param selectedCode 选中节点编码
   */
  private setSelectedInit(selectedCode: string): void {
    if (this.childrenObservable) {
      this.childrenObservable.unsubscribe();
    }
    if (selectedCode) {
      this.orgCodeSplitPipe.transform(selectedCode).subscribe(orgCodes => {
        if (orgCodes && orgCodes.length) {
          setTimeout(() => {
            this.setSelected(orgCodes);
          }, 10);
        }
      });
    }
  }

  /**
   * 设置选中
   * @param codes 编码数组
   */
  private setSelected(codes: Array<string>): void {
    if (this.dataSource && codes && codes.length) {
      const leafCode = codes[codes.length - 1];
      // 订阅当前孩子
      this.childrenObservable = this.dataSource.expandSubject.subscribe((response: boolean) => {
        if (response) {
          // 树节点
          const dataNodes = this.treeControl.dataNodes;
          if (dataNodes && dataNodes.length) {

            // 循环展开选中节点
            for (const code of codes) {
              const child = dataNodes.find(x => x.code === code);
              if (child && !this.treeControl.isExpanded(child) && !child.isLeaf) {
                //  初始化
                this.treeControl.expand(child);
                break;
              }
            }

            // 处理选中
            const leafNode = dataNodes.find(x => x.code === leafCode);
            if (leafNode) {
              const currentSelected =
                this.selectListSelection.selected && this.selectListSelection.selected.length ? this.selectListSelection.selected[0] : null;
              if (currentSelected !== leafNode) {
                // 更新选中编码
                this.defaultSelectedCode = leafCode;
                this.selectListSelection.select(leafNode);
              }
            }
          }
        }
      });
    }
  }
}

interface FlatNode {
  expandable: boolean;
  id: string;
  label: string;
  level: number;
  disabled?: boolean;
  loading?: boolean;
  [key: string]: any;
}

class DynamicDatasource implements DataSource<FlatNode> {
  private flattenedData: BehaviorSubject<FlatNode[]>;
  private childrenLoadedSet = new Set<FlatNode>();

  public expandSubject: BehaviorSubject<boolean>;

  constructor(
    private treeControl: TreeControl<FlatNode>,
    private leafType: OrgType,
    initData: FlatNode[]
  ) {
    this.flattenedData = new BehaviorSubject<FlatNode[]>(initData);
    treeControl.dataNodes = initData;
    this.expandSubject = new BehaviorSubject<boolean>(true);
  }

  connect(collectionViewer: CollectionViewer): Observable<FlatNode[]> {
    const changes = [
      collectionViewer.viewChange,
      this.treeControl.expansionModel.changed.pipe(
        tap(
          change => this.handleExpansionChange(change)
        )
      ),
      this.flattenedData
    ];
    return merge(...changes).pipe(
      map(() => {
        return this.expandFlattenedNodes(this.flattenedData.getValue());
      })
    );
  }

  expandFlattenedNodes(nodes: FlatNode[]): FlatNode[] {
    const treeControl = this.treeControl;
    const results: FlatNode[] = [];
    const currentExpand: boolean[] = [];
    currentExpand[0] = true;

    nodes.forEach(node => {
      let expand = true;
      for (let i = 0; i <= treeControl.getLevel(node); i++) {
        expand = expand && currentExpand[i];
      }
      if (expand) {
        results.push(node);
      }
      if (treeControl.isExpandable(node)) {
        currentExpand[treeControl.getLevel(node) + 1] = treeControl.isExpanded(node);
      }
    });

    return results;
  }

  handleExpansionChange(change: SelectionChange<FlatNode>): void {
    if (change.added) {
      // 加载子节点
      change.added.forEach(node => this.loadChildren(node));
    }
  }

  loadChildren(node: FlatNode): void {

    if (this.childrenLoadedSet.has(node)) {
      return;
    }
    node.loading = true;

    const treenodeService = InjectorInstance.get<TreenodeService>(TreenodeService);
    treenodeService.getByParentCode({ parentCode: node.code, orgType: node.type, leafType: this.leafType }, node.level + 1)
      .subscribe(children => {
        node.loading = false;
        const flattenedData = this.flattenedData.getValue();
        const index = flattenedData.indexOf(node);
        if (index !== -1) {
          flattenedData.splice(index + 1, 0, ...children);
          this.childrenLoadedSet.add(node);
        }
        this.flattenedData.next(flattenedData);
        // 孩子节点集合
        this.expandSubject.next(true);
      });
  }

  disconnect(): void {
    this.flattenedData.complete();
  }
}
