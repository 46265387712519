import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { CollectionViewer, DataSource, SelectionChange, SelectionModel } from '@angular/cdk/collections';
import { FlatTreeControl, TreeControl } from '@angular/cdk/tree';
import { BehaviorSubject, merge, Observable, Subscription } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { TreenodeService } from '../../../system/services/treenode.service';

import { InjectorInstance } from '../../../app.module';
import { ChildNodeInput } from 'src/app/system/models/childnode-input.model';
import { OrgType } from 'src/app/shared/models/enum';
import { OrgCodeSplitPipe } from 'src/app/shared/pipes/org-code-split.pipe';

const EXE_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => DropdownTreeComponent),
  multi: true
};

@Component({
  selector: 'app-dropdown-tree',
  templateUrl: './dropdown-tree.component.html',
  styleUrls: ['./dropdown-tree.component.less'],
  providers: [
    EXE_VALUE_ACCESSOR,
    OrgCodeSplitPipe
  ]
})
export class DropdownTreeComponent implements ControlValueAccessor, OnInit, OnChanges, AfterViewInit, OnDestroy {
  @ViewChild('input') inputDom: ElementRef<HTMLInputElement>;
  @ViewChild('dropDowmPop') dropdownPopDom: ElementRef<HTMLDivElement>;

  @Input() leafType: OrgType = 0;
  @Input() selectedTypes: Array<OrgType> = [];
  @Input() exclude: string;
  @Input() include: string;
  @Input() disabled = false;
  @Output() selectedChange: EventEmitter<FlatNode> = new EventEmitter<FlatNode>();

  // 初始化发射器
  private initSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private childrenObservable: Subscription;
  private expandObservable: Subscription;

  public dropdownHidden = true;
  public model: string;

  public treeControl: FlatTreeControl<FlatNode> = new FlatTreeControl<FlatNode>(
    node => node.level,
    node => node.expandable
  );
  public dataSource: DynamicDatasource;
  public selectListSelection = new SelectionModel<FlatNode>(false);

  constructor(
    private renderer: Renderer2,
    private orgCodeSplitPipe: OrgCodeSplitPipe,
    private treeNodeService: TreenodeService
  ) {
    // Listen to click events in the component
    renderer.listen('document', 'click', (event) => {
      this.dropdownHidden = true;
    });
    // Listen to click events in the component
    renderer.listen('document', 'touchend', (event) => {
      // Do something with 'event'
      // console.log(event, elementRef, 'doucument 全局touchend事件');
      this.dropdownHidden = true;
    });
  }

  ngOnInit(): void {
    this.selectListSelection.changed.subscribe(response => {
      let code = null;
      if (response) {
        const added = response.added;
        if (added && added.length) {
          code = response.added[0].code;
          this.selectedChange.emit(response.added[0]);
        } else {
          this.selectedChange.emit(null);
        }
      }

      // 上级节点组装
      const parentNodes: Array<FlatNode> = [];
      if (code) {
        const dataNodes = this.treeControl.dataNodes;
        const splitCodes: Array<string> = code.split('$');
        splitCodes.forEach((element, index) => {
          let dataNode;
          if (index > 0) {
            const parentCode = splitCodes.slice(0, index).join('$');
            dataNode = dataNodes.find(x => x.code === `${parentCode}$${element}`);
          } else {
            dataNode = dataNodes.find(x => x.code === element);
          }
          if (dataNode) {
            parentNodes.push(dataNode);
          }
        });
      }
      // 显示值
      this.renderer.setAttribute(this.inputDom.nativeElement, 'value', parentNodes.map(x => x.label).join('/'));
      // 更新选中值
      this.changeValue(code);

      // 取消节点监听
      if (this.childrenObservable) {
        this.childrenObservable.unsubscribe();
      }
    });

    // 加载列表数据
    this.treeNodeService
      .getByParentCode(new ChildNodeInput({ leafType: this.leafType }))
      .subscribe(response => {
        this.dataSource = new DynamicDatasource(this.treeControl, this.leafType, response);
        this.initSubject.next(true);

        // 监听树展开变更
        this.expandObservable = this.dataSource.expandSubject.subscribe((result: boolean) => {
          if (this.exclude) {
            const disabledNodes = this.treeControl.dataNodes.filter(x => x.disabled);
            disabledNodes.forEach(x => {
              x.disabled = false;
            });
            const excludeNodes = this.treeControl.dataNodes.filter(x => x.code.startsWith(this.exclude) && x.code !== this.include);
            excludeNodes.forEach(x => {
              x.disabled = true;
            });
          }
          if (this.include) {
            const includeNode = this.treeControl.dataNodes.find(x => x.code === this.include);
            if (includeNode && includeNode.disabled) {
              includeNode.disabled = false;
            }
          }
        });
      }, error => {
      }, () => {

      });
  }

  ngAfterViewInit(): void {

  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.exclude) {
      if (this.treeControl.dataNodes) {
        const disabledNodes = this.treeControl.dataNodes.filter(x => x.disabled);
        disabledNodes.forEach(x => {
          x.disabled = false;
        });
        if (this.exclude) {
          const excludeNodes = this.treeControl.dataNodes.filter(x => x.code.startsWith(this.exclude) && x.code !== this.include);
          excludeNodes.forEach(x => {
            x.disabled = true;
          });
        }
      }
    }
    if (changes.include) {
      if (this.treeControl.dataNodes) {
        const includeNode = this.treeControl.dataNodes.find(x => x.code === this.include);
        if (includeNode && includeNode.disabled) {
          includeNode.disabled = false;
        }
      }
    }
  }

  ngOnDestroy(): void {
    if (this.childrenObservable) {
      this.childrenObservable.unsubscribe();
    }
    if (this.expandObservable) {
      this.expandObservable.unsubscribe();
    }
  }

  public onModelChange: (_: any) => void = (_: any) => { };
  public onModelTouched: (_: any) => void = (_: any) => { };

  writeValue(value: string): void {
    this.model = value;

    // 监听数据变化
    this.initSubject.subscribe(response => {
      if (response) {
        this.orgCodeSplitPipe.transform(this.model).subscribe(orgCodes => {
          if (orgCodes && orgCodes.length) {
            setTimeout(() => {
              this.setSelected(orgCodes);
            }, 10);
          }
        });
      }
    });
  }

  registerOnChange(fn: any): void {
    this.onModelChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onModelTouched = fn;
  }

  /**
   * 取消事件冒泡
   * @param event 句柄
   */
  stopPropagationClickEvent(event): void {
    // console.log(event, 'stopPropagationClickEvent 点击事件');
    if (event && event.stopPropagation) {
      // this code is for Mozilla and Opera
      event.stopPropagation();
    } else if (window.event) {
      // this code is for IE
      window.event.cancelBubble = true;
    }
  }

  /**
   * 当前值
   * @param event 显示
   */
  dropDownClick(event: any): void {
    this.dropdownHidden = !this.dropdownHidden;
  }

  /**
   * 节点点击事件
   * @param node 节点
   */
  nodeClickEvent(node: FlatNode): void {
    this.selectListSelection.toggle(node);

    // 隐藏下拉组件
    this.dropdownHidden = true;
  }

  hasChild = (_: number, node: FlatNode) => node.expandable;

  /**
   * 根据节点名称获取节点
   * @param code 名称
   * @returns 节点
   */
  getNode(code: string): FlatNode | null {
    return this.treeControl.dataNodes.find(n => n.code === code) || null;
  }

  /**
   * 设置选中值
   * @param codes 选中节点标识
   */
  private setSelected(codes: Array<string>): void {
    if (this.dataSource && codes && codes.length) {
      const leafCode = codes[codes.length - 1];
      // 订阅当前孩子
      this.childrenObservable = this.dataSource.expandSubject.subscribe((response: boolean) => {
        if (response) {
          // 树节点
          const dataNodes = this.treeControl.dataNodes;
          if (dataNodes && dataNodes.length) {

            // 循环展开选中节点
            for (const code of codes) {
              const child = dataNodes.find(x => x.code === code);
              if (child && !this.treeControl.isExpanded(child) && !child.isLeaf) {
                //  初始化
                this.treeControl.expand(child);
                break;
              }
            }

            // 处理选中
            const leafNode = dataNodes.find(x => x.code === leafCode);
            if (leafNode) {
              const currentSelected =
                this.selectListSelection.selected && this.selectListSelection.selected.length ? this.selectListSelection.selected[0] : null;
              if (currentSelected !== leafNode) {
                // 更新当前选中项
                this.model = leafCode;
                this.selectListSelection.select(leafNode);
              }
            }
          }
        }
      });
    }
  }

  /**
   * 是否允许选中节点
   * @param node 节点
   * @returns 判断结果
   */
  public allowSelectedNode(type: number): boolean {
    if (this.selectedTypes && this.selectedTypes.length) {
      const existsItem = this.selectedTypes.find(x => x === type);
      return !!existsItem;
    } else {
      return true;
    }
  }

  /**
   * 值改变
   * @param model 图片路径
   */
  private changeValue(model: any): void {
    this.model = model;
    this.onModelChange(model);
  }
}

interface FlatNode {
  expandable: boolean;
  id: string;
  label: string;
  level: number;
  disabled?: boolean;
  loading?: boolean;
  [key: string]: any;
}

class DynamicDatasource implements DataSource<FlatNode> {
  private flattenedData: BehaviorSubject<FlatNode[]>;
  private childrenLoadedSet = new Set<FlatNode>();

  public expandSubject: BehaviorSubject<boolean>;

  constructor(
    private treeControl: TreeControl<FlatNode>,
    private leafType: number,
    initData: FlatNode[]
  ) {
    this.flattenedData = new BehaviorSubject<FlatNode[]>(initData);
    treeControl.dataNodes = initData;
    this.expandSubject = new BehaviorSubject<boolean>(true);
  }

  connect(collectionViewer: CollectionViewer): Observable<FlatNode[]> {
    const changes = [
      collectionViewer.viewChange,
      this.treeControl.expansionModel.changed.pipe(
        tap(
          change => this.handleExpansionChange(change)
        )
      ),
      this.flattenedData
    ];
    return merge(...changes).pipe(
      map(() => {
        return this.expandFlattenedNodes(this.flattenedData.getValue());
      })
    );
  }

  expandFlattenedNodes(nodes: FlatNode[]): FlatNode[] {
    const treeControl = this.treeControl;
    const results: FlatNode[] = [];
    const currentExpand: boolean[] = [];
    currentExpand[0] = true;

    nodes.forEach(node => {
      let expand = true;
      for (let i = 0; i <= treeControl.getLevel(node); i++) {
        expand = expand && currentExpand[i];
      }
      if (expand) {
        results.push(node);
      }
      if (treeControl.isExpandable(node)) {
        currentExpand[treeControl.getLevel(node) + 1] = treeControl.isExpanded(node);
      }
    });

    return results;
  }

  handleExpansionChange(change: SelectionChange<FlatNode>): void {
    if (change.added) {
      // 加载子节点
      change.added.forEach(node => this.loadChildren(node));
    }
  }

  loadChildren(node: FlatNode): void {
    if (!node) {
      return;
    }

    if (this.childrenLoadedSet.has(node)) {
      return;
    }
    node.loading = true;

    const treenodeService = InjectorInstance.get<TreenodeService>(TreenodeService);
    treenodeService.getByParentCode({ parentCode: node.code, orgType: node.type, leafType: this.leafType }, node.level + 1)
      .subscribe(children => {
        node.loading = false;
        const flattenedData = this.flattenedData.getValue();
        const index = flattenedData.indexOf(node);
        if (index !== -1) {
          flattenedData.splice(index + 1, 0, ...children);
          this.childrenLoadedSet.add(node);
        }
        this.flattenedData.next(flattenedData);
        // 展开
        this.expandSubject.next(true);
      });
  }

  disconnect(): void {
    this.flattenedData.complete();
  }
}
