import { Pipe, PipeTransform } from '@angular/core';
import { OrgStorageService } from '../storages/org-storage.service';
import { Observable, of } from 'rxjs';

@Pipe({
  name: 'orgName'
})
export class OrgNamePipe implements PipeTransform {

  constructor(private orgStorageService: OrgStorageService) {

  }

  transform(value: any): Observable<string> {
    if (!value) {
      return of('');
    }

    return this.orgStorageService.getName(value);
  }
}
