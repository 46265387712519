import { Injectable } from '@angular/core';
import { LocalStorageService } from 'angular-2-local-storage';
import { KeyValue } from '@angular/common';
import { KeyValueParentModel } from '../models/key-value-parent.model';
import { ActualaddressService } from '../../reported/services/actualaddress.service';
import { ListResponse } from '../models/list-response';
import { tap, map, shareReplay, mergeMap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { Deleted } from '../models/enum';

@Injectable({
  providedIn: 'root'
})
export class ActualaddressStorageService {

  private readonly DICKEY: string = 'ActualAddress';
  private http$: any;

  constructor(
    private localStorageService: LocalStorageService,
    private actualaddressService: ActualaddressService
  ) { }

  /**
   * 字典项初始化
   */
  public setStorage(): Observable<Array<KeyValueParentModel<string, string, string>>> {
    if (!this.http$) {
      this.http$ = this.actualaddressService.getAll().pipe(
        shareReplay()
      );
    }

    const result = this.http$.pipe(
      map((response: ListResponse<any>) => {
        if (response && !response.didError) {
          const data: Array<any> = response.model.map(x => ({
            key: x.dzqc,
            value: x.id,
            dataStatus: x.dataStatus
          })
          );

          this.localStorageService.set(this.DICKEY, data);

          return data;
        } else {
          return of(null);
        }
      })
    );

    return result;
  }

  /**
   * 获取字典项缓存
   */
  public getStorage(): Observable<Array<KeyValueParentModel<string, string, string>>> {

    const result = this.localStorageService.get<Array<KeyValueParentModel<string, string, string>>>(this.DICKEY);

    if (!result) {
      // 请求数据
      return this.setStorage();
    }

    return of(result);
  }

  /**
   * 获取有效字典项缓存
   */
  public getEffectiveStorage(): Observable<Array<KeyValueParentModel<string, string, string>>> {
    return this.getStorage().pipe(
      map((value, index) => {
        return value.filter(x => x.dataStatus !== Deleted.Deleted);
      })
    );
  }

  /**
   * 根据字典分类编码获取分类下的字典项
   * @param categoryCode 字典分类编码
   */
  public getItems(): Observable<Array<KeyValue<string, string>>> {


    const result = this.getStorage().pipe(
      map(
        (value: Array<KeyValueParentModel<string, string, string>>, index) => {
          const items = value.map(x => ({ key: x.key, value: x.value }));

          return items;
        })
    );

    return result;
  }


  /**
   * 清空缓存
   */
  clearStorage(): void {
    if (this.http$) {
      this.http$ = null;
    }

    this.localStorageService.remove(this.DICKEY);
  }

}
