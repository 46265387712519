<nz-layout class="frame-layout">
    <!-- 左侧菜单 -->
    <nz-sider style="z-index: 1; box-shadow: 2px 0 6px rgba(0,21,41,.35);" nzCollapsible [(nzCollapsed)]="isCollapsed"
        [nzTrigger]="triggerTemplate">
        <div class="sider-children">
            <nz-header class="sider-menu-index-logo">
                <img src="/assets/images/logo-header.png" style="width: 200px; height: 100%;" />
                <!-- <span>智慧社区管理系统</span> -->
            </nz-header>
            <app-nav-menu class="sider-menu" [isCollapsed]="isCollapsed"></app-nav-menu>
        </div>
    </nz-sider>

    <nz-layout class="main-layout">
        <!-- 顶部菜单 -->
        <nz-header class="header">
            <i class="trigger" nz-icon [nzType]="isCollapsed? 'menu-unfold' : 'menu-fold'" nzTheme="outline"
                style="color: rgb(0,21,41);" (click)="isCollapsed=!isCollapsed"></i>
            <div style="flex: auto;"></div>
            <!-- <button nz-button (click)="onPushMessage($event)">SignalR消息</button>
            <button nz-button (click)="onSendMessage()">RabbitMQ消息</button> -->
            <!-- 展板 -->
            <div style="display: flex; align-items: center; justify-content: center; margin-right: 16px;">
                <a [href]="apiUrl" title="下载外设助手">
                    <i nz-icon nzType="download" nzTheme="outline" style="font-size: 16px;"></i>
                </a>
            </div>
            <div style="display: flex; align-items: center; justify-content: center; margin-right: 16px;">
                <a>
                    <i nz-icon nzType="fund-projection-screen" nzTheme="outline" style="font-size: 16px;"
                        (click)="dashboardClickEvent($event)"></i>
                </a>
            </div>
            <app-nav-notice style="margin-right: 8px;"></app-nav-notice>
            <app-nav-user style="margin-left: 16px;"></app-nav-user>
            <div style="margin-left: 16px; height: 100%; display: flex; align-items: center;">
                <ng-container *ngIf="isFullScreen; else elseTemplate">
                    <a (click)="exitFullScreenClick()" style="display: flex;" title="退出全屏">
                        <i nz-icon nzType="fullscreen-exit" style="font-size: 24px;" nzTheme="outline"></i>
                    </a>
                </ng-container>
                <ng-template #elseTemplate>
                    <a (click)="fullScreenClick()" style="display: flex;" title="全屏">
                        <i nz-icon nzType="fullscreen" style="font-size: 24px;" nzTheme="outline"></i>
                    </a>
                </ng-template>
            </div>
        </nz-header>

        <!-- 容器 -->
        <nz-layout class="content-layout">
            <!-- 内容区 -->
            <nz-content class="main-content">
                <!-- 路由内容显示标签 -->
                <router-outlet></router-outlet>
            </nz-content>
        </nz-layout>
    </nz-layout>
</nz-layout>