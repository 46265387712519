import { Injectable, NgZone } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AmapLoaderService {

  private id = 'amapScript';
  // tslint:disable-next-line:variable-name
  private _defaultUrl = 'https://webapi.amap.com/maps?v=2.0&key=d4a1235cb3ae825be5b4397cdfe0330c&plugin=AMap.Geocoder';
  // tslint:disable-next-line:variable-name
  private _scriptLoaded: Promise<any>;

  constructor(
    private ngZone: NgZone
  ) { }

  /**
   * 加载
   */
  load(): Promise<any> {
    if (this._scriptLoaded) {
      return this._scriptLoaded;
    }

    const script = document.createElement('script');
    script.id = this.id;
    script.type = 'text/javascript';
    // script.async = true;
    // script.defer = true;
    script.src = this._defaultUrl;

    this._scriptLoaded = new Promise<void>((resolve: (val: any) => void, reject: (val: any) => void) => {
      script.onload = (event) => {
        this.ngZone.run(() => {
          resolve(event);
        });
      };
      script.onerror = (error: Event) => { reject(error); };
    });

    document.body.appendChild(script);

    return this._scriptLoaded;
  }

  /**
   * 移除
   */
  remove(): void {
    const script = document.getElementById(this.id);
    if (script) {
      document.body.removeChild(script);
    }
  }
}
