import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd, ActivationEnd, ActivatedRouteSnapshot } from '@angular/router';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less']
})
export class AppComponent implements OnInit {

  constructor(
    private router: Router,
    private title: Title
  ) { }

  ngOnInit(): void {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        const titleParts = this.recursivelyGenerateTitle(this.router.routerState.snapshot.root).reverse();
        this.title.setTitle(titleParts.join(' - '));
      }
    });
  }

  recursivelyGenerateTitle(snapshot: ActivatedRouteSnapshot): Array<string> {
    let titleParts: Array<string> = [];

    if (snapshot) {
      if (snapshot.firstChild) {
        titleParts = titleParts.concat(this.recursivelyGenerateTitle(snapshot.firstChild));
      }

      if (snapshot.data.breadcrumb) {
        titleParts.push(snapshot.data.breadcrumb);
      }
    }

    return titleParts;
  }
}
