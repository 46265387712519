import { BrowserModule } from '@angular/platform-browser';
import { Injector, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { LocalStorageModule } from 'angular-2-local-storage';

import { AppComponent } from './app.component';
import { FrameModule } from './frame/frame.module';
import { DatePipe } from '@angular/common';

export let InjectorInstance: Injector;

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    RouterModule,
    LocalStorageModule.forRoot({
      prefix: 'community-app',
      storageType: 'localStorage'
    }),
    // customer
    FrameModule
  ],
  providers: [
    DatePipe
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(
    private injector: Injector
  ) {
    InjectorInstance = this.injector;
  }
}
