import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { PagedResponse } from '../../shared/models/paged-response';
import { ListResponse } from '../../shared/models/list-response';
import { SingleResponse } from '../../shared/models/single-response';
import { NocontentResponse } from '../../shared/models/nocontent-response';

import { BaseService } from '../../shared/base.service';
import { AccessListModel } from '../models/access-list.model';
import { StorePagedParameter } from '../models/store-paged.parameter';
import { StoreListParameter } from '../models/store-list.parameter';
import { StoreInputModel } from '../models/store-input.model';
import { StoreDetailsModel } from '../models/store-details.model';

@Injectable({
  providedIn: 'root'
})
export class StoreService extends BaseService {
  private apiUrl = `${this.apiUrlBase}/api/store`;

  constructor(
    private http: HttpClient
  ) {
    super();
  }

  /**
   * 新增小区
   * @param model 设备
   */
  add(model?: StoreInputModel): Observable<SingleResponse<number>> {
    const url = `${this.apiUrl}`;

    return this.http.post<SingleResponse<number>>(url, model, this.httpOptions);
  }

  /**
   * 获取机构详情
   * @param id 机构ID
   */
  details(id: string): Observable<SingleResponse<StoreDetailsModel>> {
    const url = `${this.apiUrl}/${id}`;
    return this.http.get<SingleResponse<StoreDetailsModel>>(url);
  }

  /**
   * 更新机构
   * @param inputModel 机构实体
   */
  update(inputModel: StoreInputModel): Observable<NocontentResponse> {
    // 请求url
    const url = `${this.apiUrl}/${inputModel.id}`;
    return this.http.put<NocontentResponse>(url, inputModel, this.httpOptions);
  }

  /**
   * 删除
   * @param id ID
   */
  delete(id: string): Observable<NocontentResponse> {
    const url = `${this.apiUrl}/${id}`;
    return this.http.delete<NocontentResponse>(url, this.httpOptions);
  }

  /**
   * 撤销删除
   * @param id ID
   */
  undelete(id: string): Observable<NocontentResponse> {
    const url = `${this.apiUrl}/undelete/${id}`;
    return this.http.delete<NocontentResponse>(url, this.httpOptions);
  }


  /**
   * 获取分页列表
   * @param parameter 查询参数
   */
  paged(parameter: StorePagedParameter): Observable<PagedResponse<any>> {
    // 参数处理
    const params = this.removeEmptyProps(parameter);
    return this.http.get<PagedResponse<any>>(`${this.apiUrl}`, { params });
  }

  /**
   * 获取全部小区列表
   * @param parameter 查询参数
   */
  list(parameter: StoreListParameter): Observable<PagedResponse<any>> {
    // 参数处理
    const params = this.removeEmptyProps(parameter);
    return this.http.get<PagedResponse<any>>(`${this.apiUrl}/list`, { params });
  }

  /**
   * 获取详情
   * @param id ID
   */
  getByCode(code?: string): Observable<SingleResponse<any>> {
    const url = `${this.apiUrl}/getByCode?code=${code}`;
    return this.http.get<SingleResponse<any>>(url);
  }

  /**
   * 获取小区详情
   * @param id ID
   */
  getDetailsByCode(code: string): Observable<SingleResponse<any>> {
    const url = `${this.apiUrl}/getDetailsByCode?code=${code}`;
    return this.http.get<SingleResponse<any>>(url);
  }

}
