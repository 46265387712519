import { Injectable } from '@angular/core';
import { UserResourceStorageService } from './user-resource-storage.service';
import { CityStorageService } from './city-storage.service';
import { DictStorageService } from './dict-storage.service';
import { forkJoin, Observable } from 'rxjs';
import { ActualaddressStorageService } from './actualaddress-storage.service';
import { ActualcompanySrorageService } from './actualcompany-srorage.service';

@Injectable({
  providedIn: 'root'
})
export class IndexStorageService {

  constructor(
    private userResourceStorageService: UserResourceStorageService,
    private cityStorageService: CityStorageService,
    private dictStorageService: DictStorageService,
    private actualaddressStorageService: ActualaddressStorageService,
    private actualcompanySrorageService: ActualcompanySrorageService,
  ) { }

  /**
   * 初始化数据
   */
  initStorage(): Observable<any> {
    return forkJoin([
      // 用户资源
      this.userResourceStorageService.setStorage(),
      // 字典
      this.dictStorageService.setStorage(),
      // 地址
      this.actualaddressStorageService.setStorage(),
      // 单位
      this.actualcompanySrorageService.setStorage(),

    ]);
  }

  clearStorage(): void {
    // 清空用户资源
    this.userResourceStorageService.clearStorage();
    // 城市
    this.cityStorageService.clearStorage();
    // 字典
    this.dictStorageService.clearStorage();
    // 地址
    this.actualaddressStorageService.clearStorage();
    // 单位
    this.actualcompanySrorageService.clearStorage();
  }
}
