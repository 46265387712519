import { Directive, forwardRef, Attribute, OnChanges, SimpleChanges } from '@angular/core';
import { NG_VALIDATORS, Validator, AbstractControl, ValidationErrors } from '@angular/forms';

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[validateEqual][formControlName],[validateEqual][formControl],[validateEqual][ngModel]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => EqualValidatorDirective),
      multi: true
    }
  ]
})
export class EqualValidatorDirective implements Validator, OnChanges {
  constructor(
    @Attribute('validateEqual') public validateEqual: string,
    @Attribute('reverse') public reverse: string
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
  }

  private get isReverse(): boolean {
    if (!this.reverse) {
      return false;
    }

    return this.reverse === 'true' ? true : false;
  }

  validate(control: AbstractControl): ValidationErrors {
    // self value
    const v = control.value;

    // control vlaue
    const e = control.root.get(this.validateEqual);

    // value not equal
    if (e && v !== e.value && !this.isReverse) {
      return {
        validateEqual: true
      };
    }

    // value equal and reverse
    if (e && v === e.value && this.isReverse) {
      if (e.errors) {
        delete e.errors.validateEqual;

        if (!Object.keys(e.errors).length) {
          e.setErrors(null);
        }
      }
    }

    // value not equal and reverse
    if (e && v !== e.value && this.isReverse) {
      e.setErrors({
        validateEqual: true
      });
    }

    return null;
  }

  registerOnValidatorChange?(fn: () => void): void {
  }

}
