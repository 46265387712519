import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DictPipe } from './dict.pipe';
import { DayWeekPipe } from './day-week.pipe';
import { DictNamePipe } from './dict-name.pipe';
import { OrgNamePipe } from './org-name.pipe';
import { SafeHtmlPipe } from './safe-html.pipe';
import { DictEffectivePipe } from './dict-effective.pipe';
import { OrgEffectivePipe } from './org-effective.pipe';
import { DateLimitPipe } from './date-limit.pipe';
import { UtcOffsetPipe } from './utc-offset.pipe';
import { InternetSrcPipe } from './internet-src.pipe';
import { RelativeSrcPipe } from './relative-src.pipe';
import { CityNamePipe } from './city-name.pipe';
import { CityPipe } from './city.pipe';
import { ActualaddressPipe } from './actualaddress.pipe';
import { ActualcompanyPipe } from './actualcompany.pipe';
import { DesensitizationPipe } from './desensitization.pipe';
import { ImgSrcPipe } from './img-src.pipe';
import { DeviceImgSrcPipe } from './device-img-src.pipe';
import { OrgCodeSplitPipe } from './org-code-split.pipe';

@NgModule({
  declarations: [
    DictPipe,
    DayWeekPipe,
    DictNamePipe,
    OrgNamePipe,
    SafeHtmlPipe,
    DictEffectivePipe,
    OrgEffectivePipe,
    DateLimitPipe,
    UtcOffsetPipe,
    InternetSrcPipe,
    RelativeSrcPipe,
    CityNamePipe,
    CityPipe,
    ActualaddressPipe,
    ActualcompanyPipe,
    DesensitizationPipe,
    ImgSrcPipe,
    DeviceImgSrcPipe,
    OrgCodeSplitPipe
  ],
  imports: [
    CommonModule
  ],
  providers: [
  ],
  exports: [
    DictPipe,
    DayWeekPipe,
    DictNamePipe,
    OrgNamePipe,
    SafeHtmlPipe,
    DictEffectivePipe,
    OrgEffectivePipe,
    DateLimitPipe,
    InternetSrcPipe,
    RelativeSrcPipe,
    CityNamePipe,
    CityPipe,
    ActualaddressPipe,
    ActualcompanyPipe,
    DesensitizationPipe,
    ImgSrcPipe,
    DeviceImgSrcPipe,
    OrgCodeSplitPipe
  ]
})
export class PipesModule {
  static forRoot(): any {
    return {
      ngModule: PipesModule,
      providers: [],
    };
  }
}
