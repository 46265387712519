import { Injectable } from '@angular/core';
import { observable, of, Observable } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { catchError, map, tap } from 'rxjs/operators';

import { BaseService } from '../../shared/base.service';

import { PagedResponse } from '../../shared/models/paged-response';
import { SingleResponse } from '../../shared/models/single-response';
import { NocontentResponse } from '../../shared/models/nocontent-response';

import { UserInputModel } from '../models/user-input.model';
import { UserParameter } from '../models/User-parameter';
import { UserSearchModel } from '../models/User-search.model';
import { UserDetailsModel } from '../models/user-details.model';
import { ChangePassInputModel } from '../models/change-pass-input.model';

@Injectable({
  providedIn: 'root'
})
export class UserService extends BaseService {
  // URL to web api
  private apiUrl = `${this.apiUrlBase}/api/user`;

  constructor(
    private http: HttpClient
  ) {
    super();
  }

  /**
   * 获取用户分页集合
   * @param userParameter 查询参数
   */
  paged(parameter: UserParameter): Observable<PagedResponse<UserSearchModel>> {
    // 参数处理
    const params = this.removeEmptyProps(parameter);
    return this.http.get<PagedResponse<UserSearchModel>>(this.apiUrl, { params });
  }

  /**
   * 获取用户详情
   * @param id 用户ID
   */
  get(id: string): Observable<SingleResponse<UserDetailsModel>> {
    const url = `${this.apiUrl}/${id}`;
    return this.http.get<SingleResponse<UserDetailsModel>>(url);
  }

  /**
   * 新增用户
   * @param user 用户实体
   */
  add(user: UserInputModel): Observable<SingleResponse<UserInputModel>> {
    return this.http.post<SingleResponse<UserInputModel>>(this.apiUrl, user, this.httpOptions);
  }

  /**
   * 更新用户信息
   * @param user 用户实体
   */
  update(user: UserInputModel): Observable<NocontentResponse> {
    // 请求url
    const url = `${this.apiUrl}/${user.id}`;
    return this.http.put<NocontentResponse>(url, user, this.httpOptions);
  }

  /**
   * 修改用户密码
   * @param inputModel 输入模型
   */
  patch(inputModel: ChangePassInputModel): Observable<NocontentResponse> {
    // 请求url
    const url = `${this.apiUrl}`;
    return this.http.patch<NocontentResponse>(url, inputModel, this.httpOptions);
  }

  /**
   * 重置用户密码
   * @param id 用户ID
   */
  resetPassword(id: string): Observable<NocontentResponse> {
    // 请求url
    const url = `${this.apiUrl}/resetPassword/${id}`;
    return this.http.patch<NocontentResponse>(url, null, this.httpOptions);
  }

  /**
   * 撤销删除
   * @param id ID
   */
  undelete(id: string): Observable<NocontentResponse> {
    const url = `${this.apiUrl}/undelete/${id}`;
    return this.http.delete<NocontentResponse>(url, this.httpOptions);
  }

  /**
   * 删除用户
   * @param id 用户ID
   */
  delete(id: string): Observable<NocontentResponse> {
    const url = `${this.apiUrl}/${id}`;
    return this.http.delete<NocontentResponse>(url, this.httpOptions);
  }

  /**
   * 获取当前用户所拥有的资源
   */
  userResource(): Observable<SingleResponse<any>> {
    const url = `${this.apiUrl}/userResource`;
    return this.http.get<SingleResponse<any>>(url, this.httpOptions);
  }


  /**
   * 获取用户详情
   * @param id 用户ID
   */
  testapi(): Observable<SingleResponse<UserDetailsModel>> {
    const url = `https://localhost:5200/api/home`;
    return this.http.get<SingleResponse<UserDetailsModel>>(url);
  }
}
