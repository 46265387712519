import { Injectable } from '@angular/core';
import { BaseService } from '../../shared/base.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ListResponse } from '../../shared/models/list-response';
import { RemindSearchModel } from '../models/remind-search.model';
import { PagedResponse } from '../../shared/models/paged-response';
import { RemindPagedParameter } from '../models/remind-paged.parameter';
import { SingleResponse } from '../../shared/models/single-response';

@Injectable({
  providedIn: 'root'
})
export class RemindService extends BaseService {
  private baseUrl = `${this.apiUrlBase}/api/remind`;

  constructor(
    private http: HttpClient
  ) {
    super();
  }

  /**
   * 获取消息提醒分页列表
   */
  getPaged(parameter: RemindPagedParameter): Observable<PagedResponse<RemindSearchModel>> {
    // 参数处理
    const params = this.removeEmptyProps(parameter);

    return this.http.get<PagedResponse<RemindSearchModel>>(this.baseUrl, { params });
  }

  /**
   * 获取通知列表
   */
  getNotice(): Observable<ListResponse<RemindSearchModel>> {
    const url = `${this.baseUrl}/notice`;

    return this.http.get<PagedResponse<RemindSearchModel>>(url);
  }

  /**
   * 获取消息列表
   */
  getNews(): Observable<ListResponse<RemindSearchModel>> {
    const url = `${this.baseUrl}/news`;

    return this.http.get<PagedResponse<RemindSearchModel>>(url);
  }

  /**
   * 获取待办列表
   */
  getTodo(): Observable<ListResponse<RemindSearchModel>> {
    const url = `${this.baseUrl}/todo`;

    return this.http.get<PagedResponse<RemindSearchModel>>(url);
  }


  /**
   * 读取消息
   */
  read(model: Array<string>): Observable<SingleResponse<number>> {
    const url = `${this.baseUrl}`;
    return this.http.patch<SingleResponse<number>>(url, model);
  }
}
