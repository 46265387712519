import { Component, OnInit, Input, OnChanges, SimpleChanges, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd, Event } from '@angular/router';
import { PlatformLocation, LocationChangeEvent, LocationChangeListener } from '@angular/common';
import { UserResourceStorageService } from '../../../shared/storages/user-resource-storage.service';
import { filter } from 'rxjs/operators';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-nav-menu',
  templateUrl: './nav-menu.component.html',
  styleUrls: ['./nav-menu.component.less']
})
export class NavMenuComponent implements OnInit, OnChanges, OnDestroy {
  @Input() isCollapsed: boolean;

  private routerEventsListener: Subscription;
  menus: Array<any> = [];
  testmenus: Array<any> = [
    {
      id: '01',
      router: '/dashboard',
      name: '首页',
      icon: 'dashboard',
      open: false,
      selected: false,
      children: [
        {
          id: '0011',
          router: '/dashboard/workplace',
          name: '工作台',
          icon: 'dashboard',
          open: false,
          selected: false
        },
        {
          id: '0012',
          router: '/dashboard/analysis',
          name: '分析页',
          icon: 'dashboard',
          open: false,
          selected: false
        },
        {
          id: '0013',
          router: '/dashboard/monitor',
          name: '监控页',
          icon: 'dashboard',
          open: false,
          selected: false
        }
      ]
    }, {
      id: '02',
      router: '/canine-manage',
      name: '犬管中心',
      icon: 'laptop',
      open: false,
      selected: false,
      children: [
      ]
    }, {
      id: '04',
      router: '/information-manage',
      name: '信息管理',
      icon: 'audit',
      open: false,
      selected: false,
      children: [
        {
          id: '0041',
          router: '/information-manage/sub-list',
          name: '信息报送',
          icon: '',
          open: false,
          selected: false
        }
      ]
    }, {
      id: '05',
      router: '/system-manage',
      name: '系统管理',
      icon: 'audit',
      open: false,
      selected: false,
      children: [
        {
          id: '0051',
          router: '/system-manage/setting',
          name: '系统设置',
          icon: 'dashboard',
          open: false,
          selected: false
        },
        {
          id: '0053',
          router: '/system-manage/organization',
          name: '机构管理',
          icon: 'dashboard',
          open: false,
          selected: false
        },
        {
          id: '0054',
          router: '/system-manage/user',
          name: '用户管理',
          icon: 'dashboard',
          open: false,
          selected: false
        },
        {
          id: '0055',
          router: '/system-manage/role',
          name: '角色权限管理',
          icon: 'dashboard',
          open: false,
          selected: false
        },
        {
          id: '0058',
          router: '/system-manage/log',
          name: '系统日志',
          icon: 'dashboard',
          open: false,
          selected: false
        }
      ]
    }
  ];

  constructor(
    private router: Router,
    public location: PlatformLocation,
    private activatedRoute: ActivatedRoute,
    private userResourceStorageService: UserResourceStorageService,
  ) {
    location.onPopState(() => {
      this.reverse(this.location.pathname);
    });

    location.onHashChange(() => {

    });
  }

  ngOnInit(): void {
    // 路由监听
    this.routerEventsListener = this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((event: any) => {
      // your operation
      const url = event.urlAfterRedirects;

      this.reverse(url);
    });

    // 获取菜单资源
    this.userResourceStorageService.getMenuTree().subscribe(
      (menus: Array<any>) => {
        this.menus = menus;
        // 获取当前选中的菜单
        this.reverse(this.location.pathname);
      }
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
  }

  ngOnDestroy(): void {
    if (this.routerEventsListener) {
      this.routerEventsListener.unsubscribe();
    }
  }

  /**
   * 点击菜单项
   * @param item 菜单项
   */
  menuClick(item: any): void {
  }

  /**
   * 打开菜单
   * @param menu 菜单项
   */
  openHandler(menu: any): void {
    if (menu) {
      this.menus.forEach(x => {
        if (x.router !== menu.router) {
          x.open = false;
        }
      });
    }
  }

  reverse(router: string): void {
    if (this.menus) {
      // 设置选中项
      this.menus.forEach(x => {
        if (x.children) {
          x.children.forEach(child => {
            child.selected = child.router === router ? true : false;
          });

          // 判断父节点是否展开
          const item = x.children.find(sub => sub.router === router);
          x.open = item ? true : false;
        }
      });
    }
  }
}
