import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dateLimit'
})
export class DateLimitPipe implements PipeTransform {
  public minDate: Date = new Date(1900, 0, 1);

  transform(date: Date): any {
    if (date && Date.parse(date.toString()) > Date.parse(this.minDate.toString())) {
      return date;
    } else {
      return null;
    }
  }
}
