import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'utcOffset'
})
export class UtcOffsetPipe implements PipeTransform {

  transform(value: Date): unknown {

    return null;
  }

}
