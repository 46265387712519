import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// ng-zorro-antd
import { NzInputNumberModule } from 'ng-zorro-antd/input-number';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzMessageModule } from 'ng-zorro-antd/message';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzStepsModule } from 'ng-zorro-antd/steps';
import { NzAvatarModule } from 'ng-zorro-antd/avatar';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzUploadModule } from 'ng-zorro-antd/upload';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzDrawerModule } from 'ng-zorro-antd/drawer';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzListModule } from 'ng-zorro-antd/list';
import { NzTreeModule } from 'ng-zorro-antd/tree';
import { NzPaginationModule } from 'ng-zorro-antd/pagination';
import { NzAutocompleteModule } from 'ng-zorro-antd/auto-complete';
import { NzTreeViewModule } from 'ng-zorro-antd/tree-view';

// common
import { BottomPanelComponent } from './components/bottom-panel/bottom-panel.component';
import { CameraImageComponent } from './components/camera-image/camera-image.component';
import { IconComponent } from './components/icon/icon.component';
import { TitleComponent } from './components/title/title.component';
import { MapPointComponent } from './components/map-point/map-point.component';
// business
import { PipesModule } from '../shared/pipes/pipes.module';
import { UploadFilesComponent } from './components/upload-files/upload-files.component';
import { ImageCropperComponent } from './components/image-cropper/image-cropper.component';
import { DropdownTreeComponent } from './components/dropdown-tree/dropdown-tree.component';
import { OrgTreeComponent } from './components/org-tree/org-tree.component';
import { TablePageComponent } from './components/table-page/table-page.component';
import { OrgTreeEditComponent } from './components/org-tree-edit/org-tree-edit.component';

@NgModule({
  declarations: [
    // common
    BottomPanelComponent,
    CameraImageComponent,
    IconComponent,
    TitleComponent,
    MapPointComponent,
    ImageCropperComponent,
    // business
    UploadFilesComponent,
    DropdownTreeComponent,
    OrgTreeComponent,
    TablePageComponent,
    OrgTreeEditComponent
  ],
  entryComponents: [
    BottomPanelComponent,
    CameraImageComponent,
    IconComponent,
    TitleComponent,
    MapPointComponent,
    // business
    UploadFilesComponent,
    DropdownTreeComponent,
    OrgTreeComponent,
    TablePageComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    // ng-zorro
    NzInputNumberModule,
    NzGridModule,
    NzMessageModule,
    NzModalModule,
    NzStepsModule,
    NzAvatarModule,
    NzButtonModule,
    NzIconModule,
    NzInputModule,
    NzUploadModule,
    NzCardModule,
    NzDrawerModule,
    NzFormModule,
    NzListModule,
    NzTreeModule,
    NzTreeViewModule,
    NzPaginationModule,
    NzAutocompleteModule,
    // customer
    PipesModule.forRoot(),
  ],
  exports: [
    BottomPanelComponent,
    CameraImageComponent,
    IconComponent,
    TitleComponent,
    MapPointComponent,
    ImageCropperComponent,
    // business
    UploadFilesComponent,
    DropdownTreeComponent,
    OrgTreeComponent,
    OrgTreeEditComponent,
    TablePageComponent
  ]
})
export class BasisModule {
  static forRoot(): any {
    return {
      ngModule: BasisModule,
      providers: [],
    };
  }
}
