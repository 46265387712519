import { Injectable } from '@angular/core';
import { Observable, forkJoin, of } from 'rxjs';
import { map, shareReplay, tap, observeOn } from 'rxjs/operators';
import { LocalStorageService } from 'angular-2-local-storage';
import { HttpClient } from '@angular/common/http';
import { KeyValueParentModel } from '../models/key-value-parent.model';
import { LabelValueModel } from '../models/label-value.model';
import { isArray } from 'util';

@Injectable({
  providedIn: 'root'
})
export class CityStorageService {

  private readonly DICKEY: string = 'CITY';
  private http$: any;

  constructor(
    private localStorageService: LocalStorageService,
    private http: HttpClient
  ) { }

  /**
   * 辖区缓存初始化
   */
  public setStorage(): Observable<Array<KeyValueParentModel<string, string, string>>> {
    if (!this.http$) {
      this.http$ = this.http.get('../../assets/json/city.json').pipe(
        shareReplay()
      );
    }

    const result = this.http$.pipe(
      tap(
        (response: Array<KeyValueParentModel<string, string, string>>) => {
          let sources: Array<KeyValueParentModel<string, string, string>> = [];
          if (response) {
            // 对象转换
            sources = this.nonlinearToLinear(response);
            // 缓存数据
            this.localStorageService.set(this.DICKEY, sources);
          }
        }
      )
    );

    return result;
  }

  /**
   * 获取城市缓存
   */
  public getStorage(): Observable<Array<KeyValueParentModel<string, string, string>>> {
    // 获取缓存
    const result = this.localStorageService.get<Array<KeyValueParentModel<string, string, string>>>(this.DICKEY);

    if (!result) {
      // 请求数据
      return this.setStorage().pipe(
        map(response => {
          // 对象转换
          return this.nonlinearToLinear(response);
        })
      );
    }

    return of(result);
  }

  /**
   * 根据城市编码获取城市名称
   * @param code 城市编码
   */
  public getName(code: string): Observable<string> {
    let result = '';
    // 获取城市项
    return this.getStorage().pipe(
      map((value: Array<KeyValueParentModel<string, string, string>>, index: number) => {
        // 判断值是否存在
        if (!value) {
          return result;
        }
        const item = value.find(x => x.value === code);
        result = item ? item.key : '';
        return result;
      })
    );
  }

  /**
   * 根据城市编码获取上级城市编码集合
   * @param code 城市编码
   */
  public getParentCodes(code: string, includeCurrent: boolean = false): Observable<Array<string>> {
    // 获取辖区项
    return this.getStorage().pipe(
      map((value, index) => {
        const result: Array<string> | any = [];
        if (!value) {
          return result;
        }
        // 获取当前项
        const current = value.find(x => code && x.value === code);
        // 判断是否存在
        if (current) {
          if (includeCurrent) {
            result.push(current.value);
          }

          let parent = value.find(x => x.value === current.parent);
          while (parent) {
            result.push(parent.value);

            parent = value.find(x => x.value === parent.parent);
          }
        }

        return result.reverse();
      })
    );
  }

  /**
   * 获取省市区树
   * @param code 省市区父Code
   * @param exceptCode 排除的省市区Code
   */
  public getTree(code?: string, exceptCode?: string): Observable<Array<any>> {
    // 获取字典项
    return this.getStorage().pipe(
      map((value: Array<KeyValueParentModel<string, string, string>>, index: number) => {

        const result = this.recurrence(value, code, exceptCode);

        return result;
      })
    );
  }

  /**
   * 获取孩子节点
   * @param node 节点编码
   * @param deep 深度
   * @param disabled 是否禁用
   */
  public getSon(node: any, deep?: number, disabled?: boolean): Observable<Array<KeyValueParentModel<string, string, string>>> {
    // 获取数据
    return this.getStorage().pipe(
      map((cities: Array<KeyValueParentModel<string, string, string>>) => {
        let result;
        if (node) {
          result = cities.filter(x => x.parent === node).map(x => {

            const firstChild = cities.find(item => item.parent === x.value);
            const isLeaf = firstChild ? false : true;

            return new LabelValueModel<string, string>({
              label: x.key,
              value: x.value,
              disabled: false,
              isLeaf
            });
          });
        } else {
          result = cities.filter(x => !x.parent).map(x => {
            const firstChild = cities.find(item => item.parent === x.value);
            const isLeaf = firstChild ? false : true;

            return new LabelValueModel<string, string>({
              label: x.key,
              value: x.value,
              disabled: false,
              isLeaf
            });
          });
        }

        return result;
      }));
  }

  /**
   * 清空缓存
   */
  clearStorage(): void {
    if (this.http$) {
      this.http$ = null;
    }

    this.localStorageService.remove(this.DICKEY);
  }

  /**
   * 递归构造树
   * @param sources 数据源
   * @param code 编码
   * @param exceptCode 排除的子树
   */
  private recurrence(sources: Array<KeyValueParentModel<string, string, string>>, code?: string, exceptCode?: string | Array<string>)
    : Array<LabelValueModel<string, string>> {
    let result = [];

    if (!sources) {
      return result;
    }

    const func = (parentCode?: string, exceptCurrentCode?: string | Array<string>): Array<LabelValueModel<string, string>> => {

      if (!parentCode) {
        parentCode = null;
      }
      let items: any = [];
      if (isArray(exceptCurrentCode)) {

        items = [...sources]
          .filter(x => ((!parentCode && !x.parent) || x.parent === parentCode) && !exceptCurrentCode.includes(x.value))
          .map(x => ({ label: x.key, value: x.value }));
      } else {
        items = [...sources]
          .filter(x => ((!parentCode && !x.parent) || x.parent === parentCode) && x.value !== exceptCurrentCode)
          .map(x => ({ label: x.key, value: x.value }));
      }

      if (items) {
        items.forEach(x => {
          const children = func(x.value, exceptCurrentCode);
          if (children && children.length) {
            x.children = children;
          } else {
            x.isLeaf = true;
          }
        });
      }
      return items;
    };

    const temp = func(code, exceptCode);
    // 逻辑处理是否将当前项追加
    const current = sources.find(x => !!code && x.value === code);
    if (current && code !== exceptCode) {
      result.push({ label: current.key, value: current.value, children: temp });
    } else {
      result = temp;
    }

    return result;
  }

  /**
   * 非线性转线性
   * @param sources 数据源
   */
  private nonlinearToLinear(sources: Array<{ [key: string]: any }>)
    : Array<KeyValueParentModel<string, string, string>> {
    let result: Array<KeyValueParentModel<string, string, string>> = [];

    if (!sources) {
      return result;
    }

    const func = (children: Array<{ [key: string]: any }>, parent?: { [key: string]: any }):
      Array<KeyValueParentModel<string, string, string>> => {
      const iteration = [];

      if (!children) {
        children = null;
      }

      // 数据源
      const items: any = [...children];
      if (items) {
        items.forEach(x => {
          iteration.push({
            // 关键字
            key: x.name,
            // 值
            value: x.code,
            // 父关键字
            parent: parent ? parent.code : null
          });

          // 判断是否存在孩子节点
          if (x.children) {
            const iterationChildren = func(x.children, x);
            if (iterationChildren) {
              iteration.push(...iterationChildren);
            }
          }
        });
      }

      return iteration;
    };

    // 获取列表
    result = func(sources);

    return result;
  }

}
