<div class="login-container">
  <nz-spin [nzSpinning]="isSpinning" [nzSize]="'large'" nzTip="正在加载资源，请等待...">
    <div style="display: flex; flex-direction: column; height: 100%; width: 100%;">
      <div class="content-container">
        <div style="flex: 3; height: 100%; padding-top: 60px; text-align: center;">
          <img src="/assets/images/logo-login.png">
        </div>
        <div style="flex: 2;">
          <div class="form-container">
            <!-- <div style="text-align: center; padding-bottom: 10px; color: #00284C; font-size: 24px; font-weight: 600;">欢迎登录</div> -->
            <div style="display: flex;justify-content: space-around;width: 100%;padding: 20px 0;text-align: center;">
              <div (click)="adminSignIn(2)" class="btn" style="flex: 1;"><span
                  class="{{step==2?'active':''}}">帐号密码登录</span></div>
              <div (click)="phoneSignIn(1)" class="btn" style="flex: 1;border-left: 1px solid #999"><span
                  class="{{step==1?'active':''}}">验证码登录</span></div>
            </div>
            <!-- 帐号密码登录 -->
            <form nz-form *ngIf="step==2" class="login-form" [formGroup]="validateForm"
              (ngSubmit)="submit($event,validateForm.value)">
              <nz-form-item>
                <nz-form-control [nzErrorTip]="errorUserName">
                  <nz-input-group nzPrefixIcon="user">
                    <input type="text" [nzSize]="large" nz-input formControlName="userName" placeholder="用户名或账号">
                  </nz-input-group>
                  <ng-template #errorUserName let-control>
                    <ng-container *ngIf="control.hasError('required')">
                      请输入用户名或账号!
                    </ng-container>
                    <ng-container *ngIf="control.hasError('maxlength')">
                      用户名最多可输入20个字符!
                    </ng-container>
                  </ng-template>
                </nz-form-control>
              </nz-form-item>
              <nz-form-item>
                <nz-form-control [nzErrorTip]="errorPassword">
                  <nz-input-group nzPrefixIcon="lock" [nzSuffix]="suffixTemplate">
                    <input [type]="passwordVisible ? 'text' : 'password'" nz-input formControlName="password"
                      placeholder="密码" />
                  </nz-input-group>
                  <!--小眼睛-->
                  <ng-template #suffixTemplate>
                    <span class="eye" nz-icon [nzType]="passwordVisible ? 'eye-invisible' : 'eye'"
                      (click)="passwordVisible = !passwordVisible"></span>
                  </ng-template>
                  <ng-template #errorPassword let-control>
                    <ng-container *ngIf="control.hasError('required')">
                      请输入密码!
                    </ng-container>
                  </ng-template>
                </nz-form-control>
              </nz-form-item>
              <nz-form-item>
                <nz-form-control>
                  <label nz-checkbox formControlName="remember">
                    <span>记住我</span>
                  </label>
                  <!-- <a class="login-form-forgot" class="login-form-forgot">忘记密码</a> -->
                  <button nz-button class="login-form-button" [nzType]="'primary'"
                    [disabled]="!validateForm.valid">登录</button>
                </nz-form-control>
              </nz-form-item>
            </form>


            <!-- 手机验证码登录 -->
            <div style="position: relative;width: 100%;">
              <form nz-form *ngIf="step==1" class="login-form" [formGroup]="validateCodeForm"
                (ngSubmit)="submitCode($event,validateCodeForm.value)">
                <nz-form-item>
                  <nz-form-control [nzErrorTip]="errorUserName">
                    <nz-input-group nzPrefixIcon="user">
                      <input type="text" nz-input formControlName="phone" placeholder="手机号码">
                    </nz-input-group>
                    <ng-template #errorUserName let-control>
                      <ng-container *ngIf="control.hasError('required')">
                        请输入手机号码!
                      </ng-container>
                      <ng-container *ngIf="control.hasError('maxLength')">
                        手机号码最多可输入11个字符!
                      </ng-container>
                      <ng-container *ngIf="control.hasError('pattern')">
                        请输入正确的手机号码!
                      </ng-container>
                    </ng-template>
                  </nz-form-control>
                </nz-form-item>
                <nz-form-item>
                  <nz-form-control [nzErrorTip]="errorsmCode">
                    <nz-input-group nzPrefixIcon="lock" [nzAddOnAfter]="suffixIconButton">
                      <input nz-input formControlName="smsCode" placeholder="验证码" style="flex: 1;" />
                    </nz-input-group>

                    <ng-template #errorsmCode let-control>
                      <!-- <ng-container *ngIf="control.hasError('required')">
                        请输入验证码!
                      </ng-container> -->
                      <ng-container *ngIf="control.hasError('pattern')">
                        请输入正确的验证码!
                      </ng-container>
                      <ng-container *ngIf="control.hasError('maxLength')">
                        验证码只能输6位!
                      </ng-container>
                    </ng-template>
                  </nz-form-control>
                </nz-form-item>
                <nz-form-item>
                  <nz-form-control>
                    <label nz-checkbox formControlName="remember">
                      <span>记住我</span>
                    </label>
                    <button nz-button class="login-form-button" [nzType]="'primary'"
                      [disabled]="!validateCodeForm.valid">登录</button>
                  </nz-form-control>
                </nz-form-item>
                <ng-template #suffixIconButton>
                  <a nz-button nzType="text" style="width: 80px;" [disabled]="smsStatus"
                    (click)="onSmsCode(validateCodeForm.value)">{{smsBtnText}}</a>
                </ng-template>
              </form>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="footer-container">
    中兵卫 ©2017 版权所有
  </div> -->

    </div>
  </nz-spin>
</div>
