import { HttpClient, HttpHeaders } from '@angular/common/http';
import {
  Component,
  OnInit,
  Input,
  forwardRef,
  OnChanges,
  SimpleChanges,
  ViewChild,
  ElementRef,
  Output,
  EventEmitter,
  Renderer2
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { NzMessageService } from 'ng-zorro-antd/message';
import { ImgSrcPipe } from 'src/app/shared/pipes/img-src.pipe';
import { environment } from 'src/environments/environment';

export const EXE_DIGITAL_RANGE_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => UploadFilesComponent),
  multi: true
};

@Component({
  selector: 'app-upload-files',
  templateUrl: './upload-files.component.html',
  styleUrls: ['./upload-files.component.less'],
  providers: [
    EXE_DIGITAL_RANGE_VALUE_ACCESSOR,
    ImgSrcPipe
  ]
})
export class UploadFilesComponent implements ControlValueAccessor, OnInit, OnChanges {

  @ViewChild('upfile', { static: true }) upFile: ElementRef;

  @Input() accept: Array<any> | string = ['application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'];
  @Input() width = '100%';
  @Input() height = '100%';
  @Input() backgroundImage = 'url("/assets/images/photo-background.png")';
  @Input() msg = '上传相关文件';
  @Output() upload = new EventEmitter<any>();
  public cropped: any;
  public fileName: string;
  public file: File;
  public model: any;
  public uploadImage = this.backgroundImage;
  private apiUrl = `${environment.SERVER.URL}/api/file`;
  public onModelChange: (_: any) => void = (_: any) => { };
  public onModelTouched: (_: any) => void = (_: any) => { };

  constructor(
    private el: ElementRef,
    private http: HttpClient,
    private renderer: Renderer2,
    private imgSrcPipe: ImgSrcPipe,
    private message: NzMessageService,
  ) { }

  ngOnInit(): void {
    // 添加class
    this.renderer.addClass(this.el.nativeElement, 'ant-input');
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.backgroundImage) {
      this.uploadImage = changes.backgroundImage.currentValue;
    }
  }

  public writeValue(value: any): void {
    this.model = value;

    if (value) {
      this.uploadImage = `url(${this.imgSrcPipe.transform(value)})`;
    } else {
      this.uploadImage = this.backgroundImage;
    }
  }

  public registerOnChange(fn: any): void {
    this.onModelChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onModelTouched = fn;
  }

  /**
   * 上传文件
   * @param event event
   */
  fileUploadEvent(event: any): void {
    const target = event.target;
    // 判断数据是否存在
    if (target && target.files) {
      const file = target.files[0];
      // 判断文件是否存在
      if (file) {
        this.fileName = file.name;
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (e) => {
          const imgTarget: any = e.target;
          this.uploadImage = `url(${imgTarget.result})`;
        };
        this.cropped = file;
        this.changeValue(file);
        this.uploadClickEvent(file);
      } else {
        this.fileName = null;
        this.changeValue(null);
      }
    }
  }

  /**
   * 上传并显示照片
   * @param $event 句柄
   */
  public uploadClickEvent(files: File): void {
    if (files) {
      // Blob
      const blob: Blob = files;
      // 文件
      const file: File = files;
      // 上传文件
      this.uploadFile(file);
    }
  }

  /**
   * 上传文件
   * @param file file
   */
  uploadFile(file: File): void {
    const data: FormData = new FormData();
    data.append('file', file, file.name);
    // data.append('IsCompress', 'true');
    const header = new HttpHeaders();
    header.append('Accept', 'application/json');
    const options = { headers: header };

    // 判断文件是否存在
    if (file) {
      const url = `${this.apiUrl}`;
      this.http.post(url, data, options).subscribe((response: any) => {
        if (response.count !== 0) {
          this.changeValue(response.filePath);
          this.message.success('上传成功');
          this.upload.emit(true);
        } else {
          this.upload.emit(false);
        }
      }, err => {
        this.upload.emit(false);
      });
    }
  }

  /**
   * 移除文件
   * @param event event
   */
  removeEvent(event: any): void {
    this.uploadImage = this.backgroundImage;
    // 清空
    this.changeValue(null);
    // 清空数据
    this.upFile.nativeElement.value = null;
  }

  /**
   * 值改变
   * @param model 图片路径
   */
  private changeValue(file: File): void {
    // 判断值是否存在
    this.file = file;
    // 更新值
    this.onModelChange(file);
  }

  /**
   * 将blob转换为file
   * @param theBlob blob
   * @param fileName 文件名
   */
  private blobToFile(theBlob: any, fileName: string): any {
    theBlob.lastModifiedDate = new Date();
    theBlob.name = fileName;

    return theBlob;
  }
}
