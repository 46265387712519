import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EqualValidatorDirective } from './equal-validator.directive';
import { PhoneNumberValidatorDirective } from './phone-number-validator.directive';
import { IdCardExistsValidatorDirective } from './idcard-exists-validator.directive';

@NgModule({
  providers: [
    EqualValidatorDirective,
    PhoneNumberValidatorDirective,
    IdCardExistsValidatorDirective
  ],
  declarations: [
    EqualValidatorDirective,
    PhoneNumberValidatorDirective,
    IdCardExistsValidatorDirective
  ],
  imports: [
    CommonModule
  ],
  exports: [
    EqualValidatorDirective,
    PhoneNumberValidatorDirective,
    IdCardExistsValidatorDirective
  ]
})
export class ValidatorsModule {
  static forRoot(): any {
    return {
      ngModule: ValidatorsModule,
      providers: [],
    };
  }
}
