import { Pipe, PipeTransform } from '@angular/core';
import { environment } from 'src/environments/environment';

@Pipe({
  name: 'deviceImgSrc'
})
export class DeviceImgSrcPipe implements PipeTransform {

  private baseUrl = environment.DEVICE.URL;

  transform(value: string): any {
    let result = '';
    if (value) {
      result = `${this.baseUrl}/${value}`;
    }

    return result;
  }

}
