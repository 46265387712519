import { Injectable } from '@angular/core';
import { HttpHeaders, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BaseService {

  protected apiUrlBase = environment.SERVER.URL;
  protected httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };

  constructor() { }

  /**
   * ignore null or undefined query parameters
   * @param params 参数实体
   */
  protected removeEmptyProps(params: any): any {
    if (typeof params === 'object' && params !== null) {
      return Object.keys(params).reduce((result: HttpParams, key: string) => {
        const value: any = params[key];
        if (value !== null && value !== undefined && value !== '') {
          result[key] = value;
        }
        return result;
      }, {} as HttpParams);
    }

    return params;
  }

  /**
   * 日志记录
   * @param message 消息
   */
  protected log(message: string): void {
    // console.log(message);
    // this.messageService.add(`InfoService: ${message}`);
  }
}
