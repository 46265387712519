import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { from, Observable } from 'rxjs';

import { PagedResponse } from '../../shared/models/paged-response';
import { SingleResponse } from '../../shared/models/single-response';

import { BaseService } from '../../shared/base.service';
import { NocontentResponse } from '../../shared/models/nocontent-response';
import { ActualCompanyModel } from '../models/actual-company.model';
import { ActualCompanyPagedParameter } from '../models/actual-company.paeameter';
import { ActualCompanyListModel } from '../models/actual-company-list.model';
import { ActualCompanyDetailsModel } from '../models/actual-company-details.model';
import { ListResponse } from 'src/app/shared/models/list-response';

@Injectable({
  providedIn: 'root'
})
export class ActualcompanyService extends BaseService {

  private apiUrl = `${this.apiUrlBase}/api/actualcompany`;

  constructor(
    private http: HttpClient
  ) {
    super();
  }

  /**
   * 获取分页列表
   * @param parameter 查询参数
   */
  paged(parameter: ActualCompanyPagedParameter): Observable<PagedResponse<ActualCompanyListModel>> {
    // 参数处理
    const params = this.removeEmptyProps(parameter);
    return this.http.get<PagedResponse<ActualCompanyListModel>>(this.apiUrl, { params });
  }

  /**
   * 获取详情
   * @param id ID
   */
  details(id?: string): Observable<PagedResponse<ActualCompanyDetailsModel>> {
    const url = `${this.apiUrl}/${id}`;
    return this.http.get<PagedResponse<ActualCompanyDetailsModel>>(url);
  }

  /**
   * 获取所有角色列表
   */
  getAll(): Observable<ListResponse<ActualCompanyListModel>> {
    const url = `${this.apiUrl}/list`;
    return this.http.get<ListResponse<ActualCompanyListModel>>(url);
  }

  /**
   * 新增
   * @param model 设备
   */
  add(model?: ActualCompanyModel): Observable<SingleResponse<number>> {
    const url = `${this.apiUrl}`;

    return this.http.post<SingleResponse<number>>(url, model, this.httpOptions);
  }

  /**
   * 更新
   * @param model 设备
   */
  update(model?: ActualCompanyModel): Observable<SingleResponse<number>> {
    const url = `${this.apiUrl}/${model.id}`;

    return this.http.put<SingleResponse<number>>(url, model, this.httpOptions);
  }

  /**
   * 撤销删除
   * @param id ID
   */
  undelete(id: string): Observable<NocontentResponse> {
    const url = `${this.apiUrl}/undelete/${id}`;
    return this.http.delete<NocontentResponse>(url, this.httpOptions);
  }

  /**
   * 删除
   * @param id ID
   */
  delete(id: string): Observable<NocontentResponse> {
    const url = `${this.apiUrl}/${id}`;
    return this.http.delete<NocontentResponse>(url, this.httpOptions);
  }
}

