<!-- 上传文件组件 -->
<div [style.width]="width" [style.height]="height" [style.background-image]="uploadImage" class="container">
  <div class="photo-container">
    <div>
      <img src="/assets/images/photo.png" style="width: 50px; height: 50px;">
      <div style="margin-top: 10px;">
        <ng-container *ngIf="fileName; else elseTemplate">
          <span>{{fileName}}</span>
        </ng-container>
        <ng-template #elseTemplate>
          <span>{{msg}}</span>
        </ng-template>
      </div>
    </div>
  </div>

  <!-- 上传文件表单 -->
  <input #upfile class="image-input" type="file" [attr.accept]="accept" (change)="fileUploadEvent($event);" />
  <!-- 删除 -->
  <a *ngIf="!!model" style="position: absolute; right: 4px; top: 4px; cursor: pointer; z-index: 100;"
    (click)="removeEvent($event)">
    <img style="width: 24px; height: 24px;" src="/assets/images/delete.png">
  </a>
</div>
