import { Injectable } from '@angular/core';
import { InfoSubModel } from '../models/info-sub.model';
import { SingleResponse } from '../../shared/models/single-response';
import { Observable } from 'rxjs';
import { NocontentResponse } from '../../shared/models/nocontent-response';
import { InfoSubSearchModel } from '../models/info-sub-search.model';
import { PagedResponse } from '../../shared/models/paged-response';
import { InfoSubParameter } from '../models/info-sub.parameter';
import { BaseService } from '../../shared/base.service';
import { HttpClient } from '@angular/common/http';
import { RemindSearchModel } from '../models/remind-search.model';

@Injectable({
  providedIn: 'root'
})
export class InformationSentService extends BaseService {
  // URL to web api
  private informationSentUrl = `${this.apiUrlBase}/api/informationsent`;

  constructor(private http: HttpClient) {
    super();
  }

  /**
   * 获取信息报送分页集合
   * @param informationSubmissionParameter 查询参数
   */
  queryPageList(informationSubmissionParameter: InfoSubParameter)
    : Observable<PagedResponse<InfoSubSearchModel>> {
    // 参数处理
    const params = this.removeEmptyProps(informationSubmissionParameter);
    return this.http.get<PagedResponse<InfoSubSearchModel>>(this.informationSentUrl, { params });
  }

  /**
   * 获取信息报送分页集合
   * @param informationSubmissionParameter 查询参数
   */
  queryNoticePageList(informationSubmissionParameter: any | InfoSubParameter)
    : Observable<PagedResponse<InfoSubSearchModel>> {

    const url = `${this.informationSentUrl}/notice`;

    // 参数处理
    const params = this.removeEmptyProps(informationSubmissionParameter);
    return this.http.get<PagedResponse<InfoSubSearchModel>>(url, { params });
  }

  /**
   * 获取信息报送详情
   * @param id 信息报送ID
   */
  get(id: string): Observable<SingleResponse<InfoSubSearchModel>> {

    const url = `${this.informationSentUrl}/${id}`;

    return this.http.get<SingleResponse<InfoSubSearchModel>>(url);
  }

  /**
   * 获取信息报送详情
   * @param id 信息报送ID
   */
  remind(id: string): Observable<SingleResponse<RemindSearchModel>> {
    const url = `${this.informationSentUrl}/remind/${id}`;

    return this.http.get<SingleResponse<RemindSearchModel>>(url);
  }

  /**
   * 新增信息下发
   * @param informationSubmission 信息下发实体
   */
  add(informationSubmission: InfoSubModel): Observable<SingleResponse<InfoSubModel>> {

    return this.http
      .post<SingleResponse<InfoSubModel>>(this.informationSentUrl, informationSubmission, this.httpOptions);
  }

  /**
   * 更新信息下发
   * @param informationSubmission 信息实体
   */
  update(informationSubmission: InfoSubModel): Observable<NocontentResponse> {
    // 请求url
    const url = `${this.informationSentUrl}/${informationSubmission.id}`;

    return this.http.put<NocontentResponse>(url, informationSubmission, this.httpOptions);
  }

  /**
   * 撤销删除
   * @param id ID
   */
  undelete(id: string): Observable<NocontentResponse> {
    const url = `${this.informationSentUrl}/undelete/${id}`;

    return this.http.delete<NocontentResponse>(url, this.httpOptions);
  }

  /**
   * 删除信息下发
   * @param id 信息报送ID
   */
  delete(id: string): Observable<NocontentResponse> {
    const url = `${this.informationSentUrl}/${id}`;

    return this.http.delete<NocontentResponse>(url);
  }
}
