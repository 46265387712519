/**
 * 更新密码输入模型
 */
export class ChangePassInputModel {
    // 密码
    password?: string;
    // 新密码
    newPassword?: string;
    // 确认密码
    confirmNewPassword?: string;
    // 行版本
    rowVersion?: string;

    constructor(init?: Partial<ChangePassInputModel>) {
        Object.assign(this, init);
    }
}
