import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UserResourceStorageService } from '../../../shared/storages/user-resource-storage.service';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.less']
})
export class NotFoundComponent implements OnInit {

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private userResourceStorageService: UserResourceStorageService,
  ) { }

  ngOnInit(): void {
  }

  /**
   * 返回首页
   * @param $event 参数
   */
  public back($event): void {
    // 获取用户资源
    this.userResourceStorageService.getMenuTree().subscribe(
      response => {
        if (response && response[0].children && response[0].children[0]) {
          const url = response[0].children[0].router;
          this.router.navigate([url], { relativeTo: this.route });
        }
      }
    );
  }
}
