<div class="frame">
    <div class="header">
        <!-- 搜索框 -->
        <nz-input-group [nzSuffix]="suffixIconSearch">
            <input type="text" nz-input placeholder="搜索" (ngModelChange)="searchModelChange($event)" />
        </nz-input-group>
    </div>
    <!-- 树组件 -->
    <div class="tree">
        <nz-tree-view [nzTreeControl]="treeControl" [nzDataSource]="dataSource">
            <nz-tree-node *nzTreeNodeDef="let node" nzTreeNodeIndentLine>
                <nz-tree-node-toggle nzTreeNodeNoopToggle>
                    <i nz-icon nzType="file" nzTheme="outline"></i>
                </nz-tree-node-toggle>
                <nz-tree-node-option [nzDisabled]="node.disabled" [nzSelected]="selectListSelection.isSelected(node)"
                    (nzClick)="selectListSelection.toggle(node)">
                    {{ node.label }}
                </nz-tree-node-option>
            </nz-tree-node>
            <nz-tree-node *nzTreeNodeDef="let node; when: hasChild" nzTreeNodeIndentLine>
                <nz-tree-node-toggle *ngIf="!node.loading">
                    <i nz-icon [nzType]="treeControl.isExpanded(node) ? 'minus-square' : 'plus-square'"
                        nzTheme="outline" *ngIf="!node.isLeaf"></i>
                </nz-tree-node-toggle>
                <nz-tree-node-toggle *ngIf="node.loading" nzTreeNodeNoopToggle>
                    <i nz-icon nzType="loading" nzTreeNodeToggleActiveIcon *ngIf="!node.isLeaf"></i>
                </nz-tree-node-toggle>
                <nz-tree-node-option [nzDisabled]="node.disabled" [nzSelected]="selectListSelection.isSelected(node)"
                    (nzClick)="selectListSelection.toggle(node)">
                    {{ node.label }}
                </nz-tree-node-option>
            </nz-tree-node>
        </nz-tree-view>
    </div>
</div>

<ng-template #suffixIconSearch>
    <i nz-icon nzType="search"></i>
    <!-- <i nz-icon nzType="search"></i> -->
    <!-- <i nz-icon nzType="close"></i> -->
</ng-template>