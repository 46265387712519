import { Component, forwardRef, ViewChild, ElementRef, Input, OnChanges, SimpleChanges, TemplateRef, OnDestroy, Output, EventEmitter } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { PhotoHelper } from 'src/app/shared/helpers/photo-helper';
import { Observable, Observer } from 'rxjs';
import { NzMessageService } from 'ng-zorro-antd/message';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Dimensions, ImageCroppedEvent, ImageTransform } from '../../interfaces';
import { base64ToFile } from '../../utils/blob.utils';
import { ImgSrcPipe } from 'src/app/shared/pipes/img-src.pipe';
import { RelativeSrcPipe } from 'src/app/shared/pipes/relative-src.pipe';

export const EXE_IMAGE_UPLOAD_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => CameraImageComponent),
  multi: true
};

@Component({
  selector: 'app-camera-image',
  templateUrl: './camera-image.component.html',
  styleUrls: ['./camera-image.component.less'],
  providers: [
    EXE_IMAGE_UPLOAD_VALUE_ACCESSOR,
    ImgSrcPipe,
    RelativeSrcPipe
  ]
})
export class CameraImageComponent implements OnChanges, OnDestroy, ControlValueAccessor {

  @ViewChild('videoPlayer', { static: true }) videoPlayer: ElementRef;
  @ViewChild('upfile', { static: true }) upFile: ElementRef;

  private apiUrl = `${environment.SERVER.URL}/api/file`;

  /**
   * 宽度
   */
  @Input() width = '200px';
  /**
   * 高度
   */
  @Input() height = '256px';
  /**
   * 业务类型
   */
  @Input() operation = '';
  /**
   * 日期归档
   */
  @Input() isFiling = true;
  /**
   * 临时文件
   */
  @Input() isTemp = false;

  @Output() upload = new EventEmitter<any>();

  public model: string;
  // 拍照
  public isCamera = false;

  public uploadInfo: string;

  croppedImage: any = '';

  imageFile: File = null;
  containWithinAspectRatio = false;
  canvasRotation = 0;
  transform: ImageTransform = {};
  showCropper = false;

  constructor(
    private http: HttpClient,
    private message: NzMessageService,
    private imgSrcPipe: ImgSrcPipe,
    private relativeSrcPipe: RelativeSrcPipe,
    private photoHelper: PhotoHelper
  ) { }

  /**
   * 移除文件
   */
  handleRemove = (file: any) => {
    // 模型处理
    this.changeValue(null);
    return true;
  }

  ngOnChanges(changes: SimpleChanges): void {

  }

  ngOnDestroy(): void {
  }

  public onModelChange: (_: any) => void = (_: any) => { };
  public onModelTouched: (_: any) => void = (_: any) => { };

  writeValue(value: any): void {
    this.model = value;

    // 文件初始化
    if (value) {
      // 初始化
      this.downLoadFile(value);
    } else {
      this.croppedImage = '';
    }

    // 关闭摄像头
    this.isCamera = false;
  }

  registerOnChange(fn: any): void {
    this.onModelChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onModelTouched = fn;
  }

  imageCropped(event: ImageCroppedEvent): void {
    this.croppedImage = event.base64;
    // console.log(event, base64ToFile(event.base64));
  }

  /**
   * 图片加载完成
   */
  imageLoaded(): void {
    this.showCropper = true;
    // console.log('Image loaded');
  }

  cropperReady(sourceImageDimensions: Dimensions): void {
    // console.log('Cropper ready', sourceImageDimensions);
  }

  loadImageFailed(): void {
    // console.log('Load failed');
  }

  /**
   * 拍照
   * @param $event 句柄
   */
  public photoClickEvent($event: any): void {
    try {
      // 清空值
      this.changeValue(null);
      // 关闭拍照功能
      this.isCamera = false;
      // 生成文件
      this.imageFile = this.photoHelper.takePhotoFile(this.videoPlayer.nativeElement);

      // 清空值
      this.changeValue(null);
    } catch (error) {
      // 捕获异常
    } finally {
      // 关闭视频流
      this.photoHelper.stopStream();
    }
  }

  /**
   * 打开摄像头进行拍照
   * @param $event 句柄
   */
  public filmClickEvent($event: any): void {
    try {
      // 清空值
      this.changeValue(null);
      this.isCamera = true;
      // 清空已选择照片
      this.croppedImage = '';
      this.photoHelper.getMedia(this.videoPlayer.nativeElement);
    } catch (ex) {
      this.message.error('未检测到摄像头');
      this.isCamera = false;
    }
  }

  /**
   * 上传并显示照片
   * @param $event 句柄
   */
  public uploadClickEvent($event: any): void {
    if (this.croppedImage) {
      // Blob
      const blob: Blob = base64ToFile(this.croppedImage);
      // 文件
      const file: File = this.blobToFile(blob, this.imageFile.name);
      const fileExtIndex = this.imageFile.name.lastIndexOf('.');
      const ext = this.imageFile.name.substr(fileExtIndex + 1);
      if (ext === 'jpg' || ext === 'jpeg') {
        // 上传文件
        this.uploadFile(file);
      } else {
        this.message.error('只允许上传“jpg”或者“jpeg”');
      }
    }
  }

  /**
   * 上传文件
   * @param event event
   */
  browserFileUploadEvent(event): void {
    try {
      const that = this;
      const target = event.target;
      // 清空值
      this.changeValue(null);
      this.isCamera = false;

      // 判断数据是否存在
      if (target && target.files) {
        const file = target.files[0];
        const fileName = file.name;
        // 判断文件是否存在
        if (file) {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = (e: any) => {
            const imgTarget: FileReader = e.target;
            // 文件转换
            const blob = this.dataURLtoBlob(imgTarget.result.toString());
            that.imageFile = this.blobToFile(blob, fileName);
          };
        }
      }
    } catch (error) {

    } finally {
      // 关闭视频流
      this.photoHelper.stopStream();
    }
  }

  /**
   * 上传文件
   * @param file file
   */
  uploadFile(file: File): void {
    const data: FormData = new FormData();
    data.append('file', file, file.name);
    const header = new HttpHeaders();
    header.append('Accept', 'application/json');
    const options = { headers: header };

    // 判断文件是否存在
    if (file) {
      const url = `${this.apiUrl}?operation=${this.operation}&isFiling=${this.isFiling}&isTemp=${this.isTemp}`;
      this.http.post(url, data, options).subscribe((response: any) => {
        if (response.count !== 0) {
          this.changeValue(response.filePath);
          this.upload.emit(true);
        } else {
          this.upload.emit(false);
        }
      }, err => {
        this.upload.emit(false);
      });
    }
  }

  /**
   * 值改变
   * @param model 图片路径
   */
  private changeValue(model: string): void {
    this.model = model;
    this.onModelChange(model);
  }

  /**
   * base64转文件
   * @param dataUrl base64数据
   */
  private dataURLtoBlob(dataUrl: string): Blob {
    let result: Blob = null;

    if (!dataUrl) {
      return result;
    }

    try {
      const arr = dataUrl.split(',');
      const mime = arr[0].match(/:(.*?);/)[1];
      const suffix = mime.split('/')[1];
      const bstr = atob(arr[1]);
      let n = bstr.length;
      const u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }

      // 转换成成blob对象
      result = new Blob([u8arr], { type: mime });

      return result;
    } catch (ex) {
      // console.log(ex, 'dataURLtoBlob===拍照异常');

      throw ex;
    }
  }

  /**
   * 将blob转换为file
   * @param theBlob blob
   * @param fileName 文件名
   */
  private blobToFile(theBlob: any, fileName: string): any {
    theBlob.lastModifiedDate = new Date();
    theBlob.name = fileName;

    return theBlob;
  }

  /**
   * 下载文件
   * @param fileUrl 文件路径
   */
  private downLoadFile(relativeUrl: string): void {
    // 文件信息
    const fileUrl = this.imgSrcPipe.transform(relativeUrl);
    // 显示照片
    this.croppedImage = fileUrl;

    try {
      const image = new Image();
      // 加载文件
      image.onload = () => {
        try {
          const width = image.width ? image.width : 200;
          const height = image.height ? image.height : 200;

          const canvas: HTMLCanvasElement = document.createElement('canvas');
          canvas.width = width * 4;
          canvas.height = height * 4;
          const ctx = canvas.getContext('2d');
          ctx.drawImage(image, 0, 0, canvas.width, canvas.height);

          const fileExtIndex = fileUrl.lastIndexOf('.');
          const fileExt = fileUrl.substring(fileExtIndex + 1);
          let imgType = 'image/jpeg';
          // 文件类型处理
          switch (fileExt) {
            case 'png':
              imgType = 'image/png';
              break;
            case 'jpg':
              imgType = 'image/jpeg';
              break;
            case 'bmp':
              imgType = 'image/bmp';
              break;
            case 'tiff':
              imgType = 'image/tiff';
              break;
            case 'gif':
              imgType = 'image/gif';
              break;
            default:
              break;
          }

          // 返回base64图片
          const dataURL = canvas.toDataURL(imgType);
          // 文件转换
          const blob = this.dataURLtoBlob(dataURL);
          // 文件处理
          const fileNameIndex = fileUrl.lastIndexOf('/');
          const fileName = fileUrl.substring(fileNameIndex + 1);

          // 照片原始图片
          this.imageFile = this.blobToFile(blob, fileName);
        } catch (ex) {
          throw ex;
        }
      };
      image.onerror = (args) => {

      };
      // 设置值为 anonymous、use-credentials，作用都是设置通过CORS来请求图片，区别在于use-credentials 是加了证书的CORS，如果设置了其他值，默认会当作anonymous来处理。
      image.crossOrigin = 'anonymous';
      // image.crossOrigin = 'use-credentials';
      image.src = fileUrl;
    } catch (error) {

    } finally {

    }
  }
}
