/**
 * 获取下级机构类型
 */
export class ChildNodeInput {

    /**
     * 机构类型
     */
    public orgType?: number;

    /**
     * 父节点
     */
    public parentCode?: string;

    /**
     * 叶子节点类型
     */
    public leafType?: number;

    /**
     * 构造器
     * @param init 初始化对象
     */
    constructor(init?: Partial<ChildNodeInput>) {
        Object.assign(this, init);
    }

}
