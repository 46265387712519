<div class="frame">
  <!-- 业务核心 -->
  <div class="core">
    <!-- 显示区 -->
    <div class="content" [ngStyle]="{'width': width, 'height': height}" style="min-height: 128px; min-width: 128px;">
      <!-- 图片剪裁 -->
      <app-image-cropper [hidden]="isCamera" [imageFile]="imageFile" [maintainAspectRatio]="true"
        [containWithinAspectRatio]="containWithinAspectRatio" [aspectRatio]="3 / 4" [resizeToWidth]="256"
        [cropperMinWidth]="128" [onlyScaleDown]="true" [roundCropper]="false" [canvasRotation]="canvasRotation"
        [transform]="transform" [alignImage]="'left'" [style.display]="showCropper ? null : 'none'" format="jpeg"
        (imageCropped)="imageCropped($event)" (imageLoaded)="imageLoaded()" (cropperReady)="cropperReady($event)"
        (loadImageFailed)="loadImageFailed()"></app-image-cropper>
      <!-- 拍照Video -->
      <video #videoPlayer class="video-player" [hidden]="!isCamera" [attr.width]="width" [attr.height]="height"></video>
    </div>
    <!-- 操作区 -->
    <div class="operation">
      <div [hidden]="isCamera" (click)="filmClickEvent($event)">打开摄像头</div>
      <div [hidden]="!isCamera" (click)="photoClickEvent($event)">一键拍照</div>
      <div>
        浏览照片
        <input #upfile class="image-input" type="file" accept="image/*" title="浏览照片"
          (change)="browserFileUploadEvent($event);" />
      </div>
      <div *ngIf="croppedImage" (click)="uploadClickEvent($event)">上传照片</div>
    </div>
  </div>
  <!-- 图片显示区 -->
  <div class="img-show">
    <img [src]="croppedImage" style="max-height: 178px;" />
  </div>
</div>