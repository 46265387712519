import {
  AfterViewInit,
  Component,
  ComponentFactoryResolver,
  ComponentRef,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import { NzModalService } from 'ng-zorro-antd/modal';
import { TablePageOptions } from '../../models/table-page.options';

@Component({
  selector: 'app-table-page',
  templateUrl: './table-page.component.html',
  styleUrls: ['./table-page.component.less']
})
export class TablePageComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {

  @ViewChild('body', { read: ViewContainerRef, static: true }) body: ViewContainerRef;

  /**
   * 视图
   */
  @Input() modal: TablePageOptions<any>;
  /**
   * 刷新列表
   */
  @Output() refresh = new EventEmitter<any>();

  private timeout: NodeJS.Timeout;
  private contentRef: ComponentRef<any>;

  public headerHidden = true;
  public leftHidden = true;
  public queryHidden = true;
  public rightHidden = true;
  public footerHidden = true;
  public middleType: 'table' | 'form' = 'table';
  public title: any;
  public submitLabel = '提交';
  public cancelLabel = '返回列表';

  /**
   * 按钮加载等待
   */
  public get submitLoading(): boolean {
    let result = false;
    if (this.middleType === 'table') {
      return result;
    }

    if (this.modal && this.modal.footer && this.modal.footer.length > 0 && this.contentRef) {
      const loading = this.modal.footer[0].loading;
      if (loading !== undefined && loading !== null) {
        if (typeof loading === 'boolean') {
          result = loading;
        } else {
          result = loading(this.contentRef.instance);
        }
      }
    }

    return result;
  }

  /**
   * 按钮是否可点击
   */
  public get submitDisabled(): boolean {
    let result = true;
    if (this.middleType === 'table') {
      return result;
    }

    if (this.modal && this.modal.footer && this.modal.footer.length > 0 && this.contentRef) {
      const disabled = this.modal.footer[0].disabled;
      if (disabled !== undefined && disabled !== null) {
        if (typeof disabled === 'boolean') {
          result = disabled;
        } else {
          result = disabled(this.contentRef.instance);
        }
      }
    }

    return result;
  }

  constructor(
    private el: ElementRef,
    private cfr: ComponentFactoryResolver,
    private modalService: NzModalService
  ) { }

  ngOnInit(): void {

  }

  ngAfterViewInit(): void {
    const headerDom = this.el.nativeElement.querySelector('.t-page-header');
    const leftDom = this.el.nativeElement.querySelector('.t-page-left');
    const queryDom = this.el.nativeElement.querySelector('.t-page-query');
    const rightDom = this.el.nativeElement.querySelector('.t-page-right');
    const footerDom = this.el.nativeElement.querySelector('.t-page-footer');

    this.timeout = setTimeout(() => {
      if (headerDom) {
        this.headerHidden = false;
      }
      if (leftDom) {
        if (this.modal && !this.modal.showLeft) {
          this.leftHidden = true;
        } else {
          this.leftHidden = false;
        }
      } else {
        this.leftHidden = true;
      }
      if (queryDom) {
        this.queryHidden = false;
      }
      if (rightDom) {
        if (this.modal && !this.modal.showRight) {
          this.rightHidden = true;
        } else {
          this.rightHidden = false;
        }
      } else {
        this.rightHidden = true;
      }
      if (footerDom) {
        this.footerHidden = false;
      }
    }, 100);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.modal) {
      // 回收组件
      this.destroy();
      // 新组件
      if (changes.modal.currentValue) {
        // 初始化组件
        this.initDynamicComponent(this.modal);

        // 基本信息初始化
        if (this.modal.footer) {
          if (this.modal.footer.length > 0) {
            this.submitLabel = this.modal.footer[0].label;
          }
          if (this.modal.footer.length > 1) {
            this.cancelLabel = this.modal.footer[1].label;
          }
        }

        // 切换视图
        this.middleType = 'form';
        // 是否显示扩展容器
        this.leftHidden = !this.modal.showLeft;
        // 是否显示扩展容器
        this.rightHidden = !this.modal.showRight;
      } else {
        // 切换视图
        this.tableLayout();
      }
    }
  }

  ngOnDestroy(): void {
    clearTimeout(this.timeout);

    // 销毁弹窗
    this.destroy();
  }

  /**
   * 提交事件
   * @param event 参数
   */
  public submitEvent(event: any): void {
    if (this.contentRef && this.modal && this.modal.footer && this.modal.footer.length > 0) {
      const submitCallback = this.modal.footer[0];
      if (submitCallback.onClick) {
        submitCallback.onClick(this.contentRef.instance);
      }
    }
  }

  /**
   * 取消事件
   * @param event 句柄
   */
  public cancelEvent(event: any): void {
    if (this.contentRef && this.modal && this.modal.footer && this.modal.footer.length > 1) {
      const cancelCallback = this.modal.footer[1];
      if (cancelCallback.onClick) {
        cancelCallback.onClick(this.contentRef.instance);
      }
    }
  }

  /**
   * 关闭表单
   */
  public close(confirm?: boolean): void {
    if (confirm) {
      this.modalService.confirm({
        nzTitle: '<i>跳转提醒?</i>',
        nzContent: '<b>请选择继续录入下一条数据或返回列表页面</b>',
        nzOkText: '录入下一条',
        nzCancelText: '返回列表',
        nzOnOk: () => {
          // 录入下一条
          this.refresh.emit(true);
        },
        nzOnCancel: () => {
          // 返回列表
          this.refresh.emit(true);

          // 切换视图
          this.tableLayout();
          // 回收句柄
          this.destroy();
        }
      });
    } else {
      this.refresh.emit(true);
    }
  }

  /**
   * 回收
   */
  private destroy(): void {
    // 回收句柄
    if (this.contentRef) {
      this.contentRef.destroy();
      this.contentRef = null;
    }
  }

  /**
   * 动态组件初始化
   * @param config 配置
   */
  private initDynamicComponent(config: TablePageOptions<any>): void {
    if (config) {
      // 标题
      this.title = config.title;

      // 初始化组件
      if (config.content) {
        const com = this.cfr.resolveComponentFactory(config.content);
        this.contentRef = this.body.createComponent(com);

        // 循环处理参数
        if (config.componentParams) {
          const keyValues = Object.entries(config.componentParams);
          for (const [k, v] of keyValues) {
            // 获取Input参数
            const input = com.inputs.find(x => x.propName === k);
            if (input) {
              this.contentRef.instance[input.propName] = v;
            }

            // 输出参数
            const output = com.outputs.find(x => x.propName === k);
            if (output) {
              this.contentRef.instance[output.propName].subscribe(response => {
                v(response);
              });
            }
          }
          // 属性变更
          this.contentRef.changeDetectorRef.detectChanges();
        }

        // 处理输出参数
        if (config.afterClose) {
          const output = com.outputs.find(x => x.propName === 'afterClose');
          if (output) {
            this.contentRef.instance[output.propName] = config.afterClose;
          }
        }
      }
    }
  }

  /**
   * 表格Layout
   */
  private tableLayout(): void {
    this.middleType = 'table';
    this.leftHidden = !this.el.nativeElement.querySelector('.t-page-left');
    this.rightHidden = !this.el.nativeElement.querySelector('.t-page-right');
  }

}
