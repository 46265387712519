import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '../shared/guards/auth.guard';
import { HomeComponent } from './pages/home/home.component';
import { LoginComponent } from './pages/login/login.component';

import { NoPermissionComponent } from './pages/no-permission/no-permission.component';

// 路由信息
const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        redirectTo: 'business',
        pathMatch: 'full'
      },
      {
        path: 'system',
        loadChildren: () => import('../system/system.module').then(m => m.SystemModule),
        data: {
          breadcrumb: '系统管理'
        },
        canActivateChild: [AuthGuard]
      },
      {
        path: 'business',
        loadChildren: () => import('../business/business.module').then(m => m.BusinessModule),
        data: {
          breadcrumb: '业务管理'
        },
        canActivateChild: [AuthGuard]
      },
      {
        path: 'reported',
        loadChildren: () => import('../reported/reported.module').then(m => m.ReportedModule),
        data: {
          breadcrumb: '推送管理'
        },
        canActivateChild: [AuthGuard]
      },
      {
        path: 'no-permission',
        component: NoPermissionComponent,
        data: {
          breadcrumb: '无权限访问'
        }
      }
    ]
  },
  {
    path: 'login',
    component: LoginComponent,
    data: {
      breadcrumb: '登录'
    }
  },
  {
    path: 'dashboard',
    loadChildren: () => import('../dashboard/dashboard.module').then(m => m.DashboardModule),
    data: {
      breadcrumb: '展板'
    },
    // canActivateChild: [AuthGuard]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class FrameRoutingModule { }
