import { Pipe, PipeTransform } from '@angular/core';
import { Observable, of } from 'rxjs';
import { KeyValue } from '@angular/common';
import { DictStorageService } from '../storages/dict-storage.service';

@Pipe({
  name: 'dictEffective'
})
export class DictEffectivePipe implements PipeTransform {

  constructor(
    private dictStorageService: DictStorageService
  ) { }

  transform(value: string, include?: string): Observable<Array<KeyValue<string, string>>> {
    if (!value) {
      return of([]);
    }

    return this.dictStorageService.getEffectiveItems(value, include);
  }
}
