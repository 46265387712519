import { Injectable } from '@angular/core';
import { LocalStorageService } from 'angular-2-local-storage';
import { KeyValueParentModel } from '../models/key-value-parent.model';
import { LabelValueModel } from '../models/label-value.model';
import { OrgService } from '../../system/services/org.service';
import { tap, map, shareReplay, publishReplay, refCount } from 'rxjs/operators';
import { ListResponse } from '../models/list-response';
import { Observable, of, forkJoin } from 'rxjs';
import { Deleted } from '../models/enum';

@Injectable({
  providedIn: 'root'
})
export class OrgStorageService {

  private readonly DICKEY: string = 'ORGANIZATION_KEY';
  private http$: any;

  constructor(
    private localStorageService: LocalStorageService,
    private orgService: OrgService
  ) { }

  /**
   * 组织机构缓存初始化
   */
  public setStorage(): Observable<Array<any>> {
    if (!this.http$) {
      this.http$ = this.orgService.getAll().pipe(
        // shareReplay(1)
        publishReplay(),
        refCount()
      );
    }

    const result = this.http$.pipe(
      map(
        (response: ListResponse<any>) => {
          let data = [];
          if (!response.didError) {
            this.localStorageService.set(this.DICKEY, response.model);
            data = response.model;
          }

          return data;
        }
      )
    );

    return result;
  }

  /**
   * 获取组织机构缓存
   */
  public getStorage(): Observable<Array<KeyValueParentModel<string, string, string>>> {

    const result = this.localStorageService.get<Array<KeyValueParentModel<string, string, string>>>(this.DICKEY);

    if (!result) {
      // 重新拉取数据
      return this.setStorage();
    }

    return of(result);
  }

  /**
   * 获取有效机构缓存
   */
  public getEffectiveStorage(): Observable<Array<KeyValueParentModel<string, string, string>>> {
    return this.getStorage().pipe(
      map((value, index) => {
        return value.filter(x => x.dataStatus !== Deleted.Deleted);
      })
    );
  }

  /**
   * 根据机构编码获取组织机构名称
   * @param code 机构编码
   */
  public getName(code: string): Observable<string> {
    let result = '';

    if (!code) {
      return of(result);
    }

    // 获取字典项
    return this.getStorage().pipe(
      map(
        (value: Array<KeyValueParentModel<string, string, string>>, index: number) => {
          if (!value) {
            return result;
          }

          const item = value.find(x => x.value === code);
          result = item ? item.key : '';

          return result;
        }
      )
    );
  }

  /**
   * 获取机构层级编码
   * @param code 编码
   * @param includeCurrent 是否包括当前项
   */
  public getParentCodes(code: string, includeCurrent: boolean = false): Array<string> {
    let result: Array<string> = new Array<string>();
    if (code) {
      const codes = code.split('$');
      codes.forEach(x => {
        if (result.length) {
          result.push(`${result[result.length - 1]}$${x}`);
        } else {
          result.push(`${x}`);
        }
      });
    }

    if (!includeCurrent && result.length) {
      result = result.slice(0, result.length - 2);
    }

    return result;
  }

  /**
   * 获取组织机构树
   * @param id 组织机构父ID
   * @param exceptId 排除的组织机构ID
   */
  public getTree(id?: string, exceptId?: string): Observable<Array<LabelValueModel<string, string>>> {
    // 获取组织机构缓存
    return this.getStorage().pipe(
      map((value: Array<KeyValueParentModel<string, string, string>>, index: number) => {
        const result = this.recurrence(value, id, exceptId);

        return result;
      })
    );
  }

  /**
   * 获取有效组织机构树
   * @param code 机构编码
   * @param exceptCode 排除的机构编码
   * @param includeCode 包括的机构编码
   */
  public getEffectiveTree(code?: string, exceptCode?: string, includeCode?: string): Observable<Array<LabelValueModel<string, string>>> {
    // 判断是否包含特定项
    if (includeCode) {
      return forkJoin(
        this.getParentCodes(includeCode, true),
        this.getStorage(),
      ).pipe(
        map(
          (value: Array<any>, index: number) => {
            // 获取所有上级编码
            const parentCodes: Array<string> = value[0] ? value[0] : [];

            // 获取字典项
            const organizations: Array<KeyValueParentModel<string, string, string>> =
              value[1] ? value[1].filter(x => x.dataStatus !== Deleted.Deleted || parentCodes.indexOf(x.value) >= 0) : [];
            const result = this.recurrence(organizations, code, exceptCode);

            return result;
          }
        )
      );
    } else {
      // 获取有效组织机构缓存
      return this.getEffectiveStorage().pipe(
        map((value: Array<KeyValueParentModel<string, string, string>>, index: number) => {
          const result = this.recurrence(value, code, exceptCode);

          return result;
        })
      );
    }
  }

  /**
   * 递归构造树
   * @param sources 数据源
   * @param code 编码
   * @param exceptCode 排除子树编码
   */
  private recurrence(sources: Array<KeyValueParentModel<string, string, string>>, code?: string, exceptCode?: string)
    : Array<LabelValueModel<string, string>> {
    let result = [];

    if (!sources) {
      return result;
    }

    const func = (parentCode?: string, exceptCurrentCode?: string): Array<LabelValueModel<string, string>> => {

      if (!parentCode) {
        parentCode = null;
      }

      const items: any = [...sources]
        .filter(x => x.parent === parentCode && x.value !== exceptCurrentCode)
        .map(x => ({ label: x.key, value: x.value, isLeaf: false }));

      if (items) {
        items.forEach(x => {
          const children = func(x.value, exceptCurrentCode);
          if (children && children.length) {
            x.children = children;
          } else {
            x.isLeaf = true;
          }
        });
      }

      return items;
    };

    const temp = func(code, exceptCode);

    // 逻辑处理是否将当前项追加
    const current = sources.find(x => !!code && x.value === code);
    if (current && code !== exceptCode) {
      result.push({ label: current.key, value: current.value, children: temp });
    } else {
      result = temp;
    }

    return result;
  }

  /**
   * 清空缓存
   */
  clearStorage(): void {
    if (this.http$) {
      this.http$ = null;
    }

    this.localStorageService.remove(this.DICKEY);
  }

}
