// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  JWTToken: {
    client_id: 'angular.community',
    client_secret: 'angular.P@ssw0rd_1@3$5^',
    grant_type: 3,
  },
  SERVER: {
    URL: 'https://communityapi.sxzbxx.com' // 'http://localhost:5683' // 'https://communityapi.sxzbxx.com',// 'http://1.82.235.72:5683'
  },
  DEVICE: {
    URL: 'http://113.140.25.86:8678'
  },
  MQTT: {
    protocol: 'wss',
    host: 'mq.banggongshe.cn',
    port: 443,
    path: '',
    user: 'admin',
    password: 'admin'
  },
  MAP: {
    // 高德地图
    AMAP: 'd4a1235cb3ae825be5b4397cdfe0330c',
    AMAPSecret: '8027cfc88e2661bac510514fe4acf0ec'
  },
  PLUGINUNIT: {
    // 插件下载链接
    URL: 'https://commappupgrade.sxzbxx.com'
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
