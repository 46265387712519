import { Component, OnInit, TemplateRef, ViewChild, OnChanges, SimpleChanges, OnDestroy, HostListener } from '@angular/core';
// tslint:disable-next-line:max-line-length
import { Router, ActivatedRoute, NavigationEnd, ActivationEnd, NavigationStart, NavigationCancel, NavigationError, RoutesRecognized } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { IndexStorageService } from '../../../shared/storages/index-storage.service';
import { UserResourceStorageService } from '../../../shared/storages/user-resource-storage.service';
import { ScreenHelper } from '../../../shared/helpers/screen-helper';
import { IMqttMessage, MqttService } from 'ngx-mqtt';
import { NzMessageService } from 'ng-zorro-antd/message';
import { StoreService } from 'src/app/business/services/store.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.less']
})
export class HomeComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild('trigger', { static: true }) customTrigger: TemplateRef<void>;

  triggerTemplate: TemplateRef<void> | null = null;

  isCollapsed = false;
  isFullScreen = false;
  public isSuperAdmin = false;
  public orgCode: string = null;
  public storeData: any;
  private topicSubscription: Subscription;
  public receivedMessages: Array<any> = [];
  public apiUrl = `${environment.PLUGINUNIT.URL}`;

  constructor(
    private router: Router,
    private storeService: StoreService,
    private indexStorageService: IndexStorageService,
    private mqttService: MqttService,
    private screenHelper: ScreenHelper,
    private userResourceStorageService: UserResourceStorageService
  ) {
  }

  ngOnInit(): void {
    this.router.events
      .subscribe((event) => {
        if (event instanceof NavigationStart) {
          // console.log(this.router, event, 'NavigationStart=============');
        } else if (event instanceof NavigationEnd) {
          // console.log(this.router.routerState.snapshot.root, event, 'NavigationEnd=============');
        } else if (event instanceof NavigationCancel) {
          // console.log(this.router, event, 'NavigationCancel=============');
        } else if (event instanceof NavigationError) {
          // console.log(this.router, event, 'NavigationError=============');
        } else if (event instanceof RoutesRecognized) {
          // console.log(this.router, event, 'RoutesRecognized=============');
        }
      });

    // 获取登录用户所属机构
    this.userResourceStorageService.getUser().subscribe(response => {
      if (response && response.organizationCode) {
        this.isSuperAdmin = response.isSuperAdmin;
        if (!this.isSuperAdmin) {
          this.getstoreDetails(response.organizationCode);
        }
      }
    });
  }

  /**
   * 获取小区详细详细
   * @param code 小区编码
   */
  getstoreDetails(code: string): void {
    this.storeService.getDetailsByCode(code).subscribe(response => {
      if (response && response.model) {
        this.storeData = response.model;
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
  }

  ngOnDestroy(): void {
    if (this.topicSubscription) {
      this.topicSubscription.unsubscribe();
    }
  }

  @HostListener('window:beforeunload', ['$event'])
  beforeUnloadHandler(event: Event): boolean {
    // 清空缓存
    this.indexStorageService.clearStorage();

    return false;
  }

  @HostListener('window:onunload', ['$event'])
  unloadHandler(event: Event): boolean {
    // 清空缓存
    this.indexStorageService.clearStorage();

    return false;
  }

  changeTrigger(): void {
    this.triggerTemplate = this.customTrigger;
  }

  /**
   * 推送消息
   * @param $event 数据对象
   */
  onPushMessage($event: any): void {
  }

  /**
   * rabbitmq消息推送
   * @param $event 句柄
   */
  onRabbitMQpushMessage($event: any): void {
    this.onRabbitMQSendMessage();
  }

  /**
   * 发送消息
   */
  onRabbitMQSendMessage(): void {
    const data: any = {
      id: '222222222222222',
      userId: '1222222',
      organizationId: '22222222',
      caption: '测试报送信息推送',
      createDate: new Date()
    };

    const message = JSON.stringify(data);

    // messages published to the default exchange are given the destination /queue/queuename
    // messages published to amq.topic are given the destination /topic/routing_key;
    // all other messages are given the destination /exchange/exchange_name[/routing_key]
    this.mqttService.unsafePublish('/exchange/topic.ex_pets.submission/demo.123', message, { qos: 0, retain: false });
  }

  onReceivedMessage(): void {
    this.topicSubscription = this.mqttService.observe('/topic/demo').subscribe((message: IMqttMessage) => {

      this.receivedMessages.push(message.payload.toString());
    });
  }

  onSendMessage(): void {

    // if (!this.rxStompService.connected()) {
    //   this.rxStompService.activate();
    // }

    // const message = `Message generated at ${new Date}`;
    // this.rxStompService.publish({ destination: '/topic/demo', body: message });
  }

  /**
   * 展板点击事件
   * @param $event 参数
   */
  dashboardClickEvent($event): void {
    if (!this.storeData) {
      this.router.navigate(['dashboard/plat-dash']);
    } else {
      this.router.navigate(['dashboard/store-dash'], {
        queryParams: {
          id: this.storeData.id,
          code: this.storeData.storeCode,
          orgCode: this.storeData.orgCode,
          unitCount: this.storeData.unitCount,
          buildingCount: this.storeData.buildingCount,
          houseCount: this.storeData.houseCount
        }
      });
    }
  }

  /**
   * 全屏
   */
  fullScreenClick(): void {
    this.isFullScreen = true;
    // 全屏
    this.screenHelper.fullScreen();
  }

  /**
   * 退出全屏
   */
  exitFullScreenClick(): void {
    this.isFullScreen = false;
    // 退出全屏
    this.screenHelper.exitScreen();
  }

}
