import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dayWeek'
})
export class DayWeekPipe implements PipeTransform {
  transform(value: any): string {
    if (value !== undefined) {
      const weekOptions = new Array('星期日', '星期一', '星期二', '星期三', '星期四', '星期五', '星期六');
      const current = new Date(value);
      const week = weekOptions[current.getDay()];
      return week;
    }
  }
}
