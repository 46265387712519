import { Pipe, PipeTransform } from '@angular/core';
import { environment } from '../../../environments/environment';

@Pipe({
  name: 'relative-src'
})
export class RelativeSrcPipe implements PipeTransform {

  private baseUrl = environment.SERVER.URL;

  transform(value: string): any {
    return value ? value.replace(`${this.baseUrl}`, '').substr(1) : value;
  }
}
