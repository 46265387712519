<div nz-row>
  <div nz-col nzSpan="24">
    <div style="text-align: center; margin-bottom: 10px;">
      <span style="font-size: 24px;">{{model?.caption}}</span>
    </div>
  </div>
</div>
<div nz-row>
  <div nz-col nzSpan="24">
    <div style="text-align: center; margin-bottom: 10px;">
      <span>报送时间：{{model?.created | date: 'yyyy-MM-dd'}}</span>
    </div>
  </div>
</div>
<div nz-row>
  <div nz-col nzSpan="24">
    <div class="ql-editor preview" style="margin-bottom: 10px;" [innerHTML]="model?.content | safeHtml"></div>
  </div>
</div>