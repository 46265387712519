import { Injectable, Directive } from '@angular/core';
import { AsyncValidator, AbstractControl, ValidationErrors, NG_ASYNC_VALIDATORS } from '@angular/forms';
import { ValidationService } from '../../frame/services/validation.service';
import { Observable } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';
import { SingleResponse } from '../models/single-response';

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[idCardExists][formControlName],[idCardExists][formControl],[idCardExists][ngModel]',
  providers: [{ provide: NG_ASYNC_VALIDATORS, useExisting: IdCardExistsValidatorDirective, multi: true }]
})
export class IdCardExistsValidatorDirective implements AsyncValidator {

  constructor(
    private validationService: ValidationService
  ) { }

  validate(control: AbstractControl): Promise<ValidationErrors> | Observable<ValidationErrors> {
    return this.validationService.idCardExists(control.value).pipe(
      tap(
        (response: SingleResponse<any>) => {
          return (response && response.model) ? { idCardExists: true } : null;
        }
      )
    );
  }
}
