<ul nz-menu [nzMode]="'inline'" [nzTheme]="'dark'" style="border-right: 1px solid transparent;"
  [nzInlineCollapsed]="isCollapsed" (nzClick)="menuClick($event)">
  <li nz-submenu *ngFor="let menu of menus" [(nzOpen)]="menu.open" (nzOpenChange)="openHandler(menu)">
    <span title>
      <i nz-icon style="font-size: 18px;" [nzType]="menu.icon"></i>
      <span class="nav-text">{{menu.name}}</span>
    </span>
    <ul>
      <li nz-menu-item *ngFor="let subMenu of menu.children" [nzSelected]="subMenu.selected"
        [routerLink]="subMenu.router">{{subMenu.name}}</li>
    </ul>
  </li>
</ul>