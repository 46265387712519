import { Component, OnInit, OnDestroy, TemplateRef, Input } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../../frame/services/auth.service';
import { NzModalService, NzModalRef } from 'ng-zorro-antd/modal';
import { NzMessageService } from 'ng-zorro-antd/message';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { UserResourceStorageService } from '../../../shared/storages/user-resource-storage.service';
import { UserService } from '../../../system/services/user.service';
import { ChangePassInputModel } from '../../../system/models/change-pass-input.model';

@Component({
  selector: 'app-nav-user',
  templateUrl: './nav-user.component.html',
  styleUrls: ['./nav-user.component.less']
})
export class NavUserComponent implements OnInit, OnDestroy {
  model: any;

  passwordEditModal: NzModalRef;
  public validateForm: FormGroup;

  /**
   * 构造器
   * @param router 路由
   * @param fb 表单
   * @param modalService 模态框
   * @param authenticationService 授权服务
   */
  constructor(
    private router: Router,
    private fb: FormBuilder,
    private message: NzMessageService,
    private modalService: NzModalService,
    private authService: AuthService,
    private userService: UserService,
    private userResourceStorageService: UserResourceStorageService,
  ) { }

  ngOnInit(): void {
    // 获取登录用户信息
    this.userResourceStorageService.getUser().subscribe(
      (user: any) => {
        this.model = user;
      }
    );

    // 表单初始化
    this.validateForm = this.fb.group({
      password: [null, [
        Validators.required
      ]],
      newPassword: [null, [
        Validators.required, 
        // 密码至少包含数字、大小写字母、特殊字符 两种以上（不包含中文及空格），长度不小于6位
        Validators.pattern(/^(?!^[0-9]+$)(?!^[a-z]+$)(?!^[A-Z]+$)(?!^[^A-z0-9]+$)^[^\s\u4e00-\u9fa5]{6,}$/)
      ]],
      confirmNewPassword: [null, [
        Validators.required, 
        // 密码至少包含数字、大小写字母、特殊字符 两种以上（不包含中文及空格），长度不小于6位
        Validators.pattern(/^(?!^[0-9]+$)(?!^[a-z]+$)(?!^[A-Z]+$)(?!^[^A-z0-9]+$)^[^\s\u4e00-\u9fa5]{6,}$/)
      ]]
    });
  }

  ngOnDestroy(): void {
    // 回收
    if (this.passwordEditModal) {
      this.passwordEditModal.destroy();
    }
  }

  resetPassword(tplContent: TemplateRef<{}>): void {
    this.passwordEditModal = this.modalService.create({
      nzTitle: '修改密码',
      nzWidth: '380px',
      nzContent: tplContent,
      nzMaskClosable: false,
      nzOnCancel: () => {
        // 关闭窗口
        this.passwordEditModal.close();
        // 表单重置
        this.reset();
      },
      nzClosable: false,
      nzOnOk: () => {
        // 修改密码
        for (const key in this.validateForm.controls) {
          if (key) {
            this.validateForm.controls[key].markAsDirty();
            this.validateForm.controls[key].updateValueAndValidity();
          }
        }

        // 是否验证成功
        if (this.validateForm.valid) {

          const model = this.validateForm.value;
          // 初始化数据
          const changePasswordModel: ChangePassInputModel = new ChangePassInputModel({
            // 密码
            password: model.password,
            // 新密码
            newPassword: model.newPassword,
            // 确认密码
            confirmNewPassword: model.confirmNewPassword,
            // 行版本
            rowVersion: this.model.userRowVersion
          });

          // 修改密码
          this.userService.patch(changePasswordModel).subscribe(response => {
            if (response && !response.didError) {
              this.message.success('用户密码修改成功');
              this.passwordEditModal.close();

              // 修改密码后需重新登录
              this.authService.logout();
            }
          });
        }

        return false;
      }
    });

    // 窗口关闭事件
    this.passwordEditModal.afterClose.subscribe(() => {
      this.reset();
    });
  }

  /**
   * 退出
   */
  logout(): void {
    this.modalService.confirm({
      nzTitle: '是否退出系统?',
      nzOnOk: () => {
        // 确认退出
        this.authService.logout();
      }
    });
  }

  /**
   * 重置表单
   * @param e 鼠标事件
   */
  public reset(): void {
    this.validateForm.reset();

    // tslint:disable-next-line:forin
    for (const key in this.validateForm.controls) {
      this.validateForm.controls[key].markAsPristine();
      this.validateForm.controls[key].updateValueAndValidity();
    }
  }
}
