export class LabelValueModel<TKey, TValue> {
    /**
     * 主键
     */
    public value?: TValue;

    /**
     * 名称
     */
    public label?: TKey;

    /**
     * 是否叶子节点
     */
    public isLeaf?: boolean;

    /**
     * 是否禁用
     */
    public disabled?: boolean;

    /**
     * 扩展
     */
    public code?: string;

    /**
     * 子节点
     */

    public children?: Array<LabelValueModel<TKey, TValue>>;

    /**
     * 构造器
     * @param init 初始化数据
     */
    constructor(init: Partial<LabelValueModel<TKey, TValue>>) {
        Object.assign(this, init);
    }
}
