import { Pipe, PipeTransform } from '@angular/core';
import { ActualaddressStorageService } from '../storages/actualaddress-storage.service';
import { of, Observable } from 'rxjs';
import { KeyValue } from '@angular/common';

@Pipe({
  name: 'actualaddress'
})
export class ActualaddressPipe implements PipeTransform {

  constructor(private actualaddressStorageService: ActualaddressStorageService) {
  }

  transform(): Observable<Array<KeyValue<string, string>>> {
    return this.actualaddressStorageService.getItems();
  }
}
