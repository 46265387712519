import { Component, OnInit, Input } from '@angular/core';
import { InfoSubSearchModel } from '../../models/info-sub-search.model';
import { RemindSearchModel } from '../../models/remind-search.model';
import { InformationSentService } from '../../services/information-sent.service';

@Component({
  selector: 'app-info-sent-details',
  templateUrl: './info-sent-details.component.html',
  styleUrls: ['./info-sent-details.component.less']
})
export class InfoSentDetailsComponent implements OnInit {
  @Input() id: string;

  @Input() model: InfoSubSearchModel;

  constructor(private informationSentService: InformationSentService) { }

  ngOnInit(): void {
    // 加载数据
    this.refreshData();
  }

  /**
   * 加载数据
   */
  private refreshData(): void {
    if (!this.id) {
      return;
    }
    if (!this.model) {
      // 请求数据
      this.informationSentService.remind(this.id).subscribe(response => {
        if (!response.didError) {
          const remindModel: RemindSearchModel = response.model;
          this.model = new InfoSubSearchModel({
            caption: remindModel?.title,
            content: remindModel?.content,
            created: remindModel?.created
          });
        }
      });
    }
  }

}
