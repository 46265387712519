import { Directive } from '@angular/core';
import { Validator, AbstractControl, ValidationErrors, NG_VALIDATORS, ValidatorFn, FormGroup } from '@angular/forms';

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[validatePhone][formControlName],[validatePhone][formControl],[validatePhone][ngModel]',
  providers: [{ provide: NG_VALIDATORS, useExisting: PhoneNumberValidatorDirective, multi: true }]
})
export class PhoneNumberValidatorDirective implements Validator {

  validate(control: AbstractControl): ValidationErrors {
    // tslint:disable-next-line:max-line-length
    return this.phoneNumberValidator(new RegExp('((/d{11})|^((/d{7,8})|(/d{4}|/d{3})-(/d{7,8})|(/d{4}|/d{3})-(/d{7,8})-(/d{4}|/d{3}|/d{2}|/d{1})|(/d{7,8})-(/d{4}|/d{3}|/d{2}|/d{1}))$)', 'i'))(control);
  }
  registerOnValidatorChange?(fn: () => void): void {
  }

  constructor() { }

  phoneNumberValidator(nameRe: RegExp): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const forbidden = nameRe.test(control.value);
      return forbidden ? { phoneNumber: { value: control.value } } : null;
    };
  }
}
