import { Injectable } from '@angular/core';

declare var ActiveXObject: any;

@Injectable({
    providedIn: 'root'
})
export class ScreenHelper {
    /**
     * 全屏
     */
    public fullScreen(): void {
        const handler = window as any;
        const el: any = document.documentElement;
        const rfs = el.requestFullScreen || el.webkitRequestFullScreen || el.mozRequestFullScreen || el.msRequestFullScreen;

        // typeof rfs != "undefined" && rfs
        if (rfs) {
            rfs.call(el);
        } else if (typeof handler.ActiveXObject !== 'undefined') {
            // for IE，这里其实就是模拟了按下键盘的F11，使浏览器全屏
            const wscript = new ActiveXObject('WScript.Shell');
            if (wscript != null) {
                wscript.SendKeys('{F11}');
            }
        }
    }

    /**
     * 退出全屏
     */
    public exitScreen(): void {
        const handler = window as any;
        const el: any = document;
        const cfs = el.cancelFullScreen || el.webkitCancelFullScreen || el.mozCancelFullScreen || el.exitFullScreen;

        // typeof cfs != "undefined" && cfs
        if (cfs) {
            cfs.call(el);
        } else if (typeof handler.ActiveXObject !== 'undefined') {
            // for IE，这里和fullScreen相同，模拟按下F11键退出全屏
            const wscript = new ActiveXObject('WScript.Shell');
            if (wscript != null) {
                wscript.SendKeys('{F11}');
            }
        }
    }
}
